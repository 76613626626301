import React from "react"
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function TourLib() {

    const {t} = useTranslation("tours")

    const styles = {
        defaultTextContent: {
            fontSize: 14
        }
    }

    const homeTourSteps = [
        {
            step: 1,
            title: t("welcome_tour"),
            subtitle: t("your_summary"),
            content: <Typography sx={styles.defaultTextContent}>{t("your_summary_text")}</Typography>,
            position: "left"
        },
        {
            step: 2,
            title: t("welcome_tour"),
            subtitle: t("your_settings"),
            content: <Typography sx={styles.defaultTextContent}>{t("your_settings_text")}</Typography>,
            position: "bottom-end"
        },
        {
            step: 3,
            title: t("welcome_tour"),
            subtitle: t("app_qrcode"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_qrcode_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 4,
            title: t("welcome_tour"),
            subtitle: t("app_vcard"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_vcard_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 5,
            title: t("welcome_tour"),
            subtitle: t("app_atlas"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_atlas_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 6,
            title: t("welcome_tour"),
            subtitle: t("app_packaging"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_packaging_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 7,
            title: t("welcome_tour"),
            subtitle: t("app_analytics"),
            content: <Typography sx={styles.defaultTextContent}>{t("app_analytics_text")}</Typography>,
            position: "bottom"
        },
        {
            step: 8,
            title: t("welcome_tour"),
            subtitle: t("support"),
            content: <Typography sx={styles.defaultTextContent}>{t("support_text")}</Typography>,
            position: "top-start"
        },
        {
            step: 9,
            title: t("welcome_tour"),
            subtitle: t("tours"),
            content: <Typography sx={styles.defaultTextContent}>{t("tours_text")}</Typography>,
            position: "right"
        },

    ]

    return {homeTourSteps}
}