import React, {useState} from "react"
import {SupportChip, SupportDialog} from "@/layout/components/support";
import {useTranslation} from "react-i18next";
import {useUser} from "@context";
import {useLocation} from "react-router-dom";
import {usePermissions} from "@/Context/User/PermissionsProvider";
import {useSnackbar} from "@hooks";
import {APIPostSupportFile, APIPostUnauthentified} from "@/API/apiCalls";

const {REACT_APP_API_URL} = process.env

export default function Support () {

    const {t} = useTranslation("common");
    const {handleSuccess, handleError} = useSnackbar()
    const {user} = useUser()
    const {trueAccountType} = usePermissions()
    const location = useLocation().pathname;

    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [supportRequest, setSupportRequest] = useState<any>({
        message: "",
        email: "",
        reason: t("product_issue")
    })
    // Images
    const [uploadedFiles, setUploadedFiles] = React.useState<any[]>([]);
    // Loading
    const [loading, setLoading] = React.useState(false);
    // Signup issue state
    let signupIssue = !user && !trueAccountType

    // Image upload
    const onDropFile = (e: any) => {
        e.preventDefault()

        setLoading(true)

        for (let i = 0; i < e.target.files.length; i++) {
            let f = e.target.files[i]

            if ((f.size / 1024 / 1024) > 10) {
                handleError(t("error_import_file"))
                continue
            }

            APIPostSupportFile(REACT_APP_API_URL + "/support/ticket/files-upload", f).then((res: any) => {
                handleSuccess(t("success_import_file"))

                let img = {
                    url: res.parsedBody.url,
                    id: res.parsedBody.id,
                }

                setUploadedFiles(prev => [...prev, img])
            }, () => {
                handleError(t("error_import_file"))
            }).catch(() => {
                handleError(t("error_import_file"))
            }).finally(() => {
                // e.target.value = null
                setLoading(false)
            })
        }

        // clear form on close
        e.target.value = null
    }

    const onDeleteFile = (image: any) => {
        setUploadedFiles(prev => [
            ...prev.filter((obj: any) => obj.url !== image.url)
        ])
    }

    const sendSupportRequest = () => {

        let ids: any[] = []
        let endpoint = "/support/ticket"
        let message = supportRequest.message
        let email = !!user ? user.email : "support@unitag.io"
        let account_type = !!trueAccountType ? trueAccountType : "Signup"

        if (!!uploadedFiles.length) {
            uploadedFiles.forEach((image: any) => {
                ids.push(Number(image.id))
            })
            endpoint = endpoint + "?withFiles=true"
        }

        if (!!supportRequest.email) {
            message = message + " || Souhaite être contacté sur " + String(supportRequest.email)
        }

        let body = {
            message: message,
            source: location,
            account_type: account_type,
            email: email,
            files_ids: ids,
            category: supportRequest.reason
        }

        APIPostUnauthentified(REACT_APP_API_URL + endpoint, body).then((res: any) => {
            if (res.ok) {
                handleSuccess(t("success_sending_support_request"))
                setSupportRequest({
                    message: "",
                    email: "",
                    reason: t("general_issue")
                })
                setUploadedFiles([])
                setOpenDialog(false)
            } else {
                handleError(t("error_sending_support_request"))
            }
        }, () => {
            handleError(t("error_sending_support_request"))
        }).catch(() => {
            handleError(t("error_sending_support_request"))
        })
    }

    // if (!user || !trueAccountType) return <></>

    return (
        <>
            <SupportChip onClickSupport={() => setOpenDialog(true)} {...{t}} />
            <SupportDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onSend={() => sendSupportRequest()}
                {...{
                    supportRequest, setSupportRequest,
                    onDropFile, onDeleteFile,
                    uploadedFiles,
                    signupIssue,
                    loading,
                    t
                }}
            />
        </>
    )
}