import React, {useEffect, useState} from "react"
import {Business, Flight, RememberMe, RocketLaunch, Send} from "@mui/icons-material";
import {
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";
import {useTranslation} from "react-i18next";
import {useUser} from "@context";

/**
 * Returns `subscriptions` and `currency` based on the `/plans` API call
 * @param frequency<"monthly" | "yearly">
 */
export default function useSubscriptions(frequency: any) {

    const {t} = useTranslation('pricing');
    const {plans, getPlans} = useUser()

    const [prices, setPrices] = useState<any>(null)
    const [currency, setCurrency] = useState<any>("usd");

    useEffect(() => {
        if (!plans) getPlans()
        else if (!!plans) {
            setPrices({
                standard: {...plans[0]},
                gold: {...plans[1]},
                platinum: {...plans[2]},
                standalone: {monthly: 2.8, yearly: 28.08}
            })
            setCurrency(plans[0].currency)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans]);

    if (!prices) return {subscriptions: null, currency: null}

    let subscriptions = {
        plans: [
            {
                label: "Standard",
                type: "standard",
                sublabel: "standard_text",
                icon: <Send />,
                qrcodes: "100",
                analytics: t("2_weeks"),
                link: { pathname: '/alacarte', state: { fromPlan: standardSubscription } },
                price: frequency === "monthly" ? prices.standard.monthly : prices.standard.yearly,
            },
            {
                label: "Gold",
                type: "gold",
                sublabel: "gold_text",
                icon: <Flight />,
                qrcodes: "500",
                analytics: t("3_months"),
                link: { pathname: '/alacarte', state: { fromPlan: goldSubscription } },
                price: frequency === "monthly" ? prices.gold.monthly : prices.gold.yearly,
            },
            {
                label: "Platinum",
                type: "platinum",
                sublabel: "platinum_text",
                icon: <RocketLaunch />,
                qrcodes: "unlimited",
                analytics: t("3_years"),
                link: { pathname: '/alacarte', state: { fromPlan: platinumSubscription } },
                price: frequency === "monthly" ? prices.platinum.monthly : prices.platinum.yearly,
            },
            {
                label: "Enterprise",
                type: "enterprise",
                sublabel: "enterprise_subtext",
                icon: <Business />,
            }
        ],
        standalone: [
            {
                label: "vCard Pro",
                type: "standalone",
                icon: <RememberMe />,
                sublabel: "i_want_standalone",
                link: { pathname: '/alacarte', state: { fromPlan: standaloneSubscription } },
                price: frequency === "monthly" ? prices.standalone.monthly : prices.standalone.yearly,
            },
            {
                label: "Enterprise",
                type: "enterprise",
                sublabel: "vcards_enterprise_text",
                buttonLabel: t("common:contact_us"),
                icon: <Business />,
            }
        ]
    }

    return {...{subscriptions, currency}}
}