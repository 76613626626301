import React from 'react';
import TermsAndConditionsContent from "@components/Legal/TermsAndConditionsContent";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function TermsAndConditions() {

    const {t} = useTranslation(("common"))

    return (
        <>
            <Button component={Link} to={"/"}>
                {t("Back")}
            </Button>
            <TermsAndConditionsContent />
            <Button component={Link} to={"/"}>
                {t("Back")}
            </Button>
        </>
    )
}
