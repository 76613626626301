import React from "react"
import {Box, Button, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function CriticalError({ error }: any) {
    const {t} = useTranslation("common")
    return (
        <Container sx={{ textAlign: "center" }} maxWidth={"sm"}>
            <Typography align={"center"} variant={"h4"} component={"h1"} fontWeight={700} mt={10}>
                {t("critical_error")}
            </Typography>

            <Typography variant={"body2"} align={"center"} my={5}>
                {t("critical_error_text")}
            </Typography>

            <Box sx={{
                border: "1px solid grey",
                borderRadius: 4,
                p: 4
            }}>
                <Typography variant={"caption"} align={"center"}>
                    {String(error)}
                </Typography>
            </Box>

            <Button onClick={() => window.location.reload()} variant={"contained"} disableElevation sx={{ mt: 5, backgroundColor: "#afc928" }}>
                {t("reload")}
            </Button>
        </Container>
    )
}