import React from "react"
import {Box, ButtonBase, Typography} from "@mui/material";
import {HelpOutline} from "@mui/icons-material";

export default function SupportChip(props: any) {

    const {t, onClickSupport} = props

    const styles = {
        chip: {
            position: "fixed",
            bottom: 15, right: 15,
            zIndex: 999,
        },
        button: {
            backgroundColor: "primary.main",
            px: 5,
            py: 2,
            borderRadius: 5,
            color: "white",
            transition: ".2s ease-in-out",
            "&:hover": {
                backgroundColor: "primary.dark"
            }
        },
        icon: {
          // mb: "-4px"
        }
    }

    return (
        <>
            <Box sx={styles.chip}>
                <ButtonBase sx={styles.button} onClick={onClickSupport}>
                    <HelpOutline fontSize={"small"} sx={styles.icon} />&nbsp;&nbsp;
                    <Typography fontWeight={600}>{t("support_chip_label")}</Typography>
                </ButtonBase>
            </Box>
        </>
    )
}