import React, {useEffect, useState} from 'react';
import ReactCodeInput from "react-code-input";
import {useHistory, useLocation, Link} from "react-router-dom";
// MUI
import {
    Box,
    FormControl,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';

// MUI ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {AccountCircle, Cancel, CheckCircle, Lock} from "@mui/icons-material";
// TRANSLATION
import {useTranslation} from 'react-i18next';
// Unitag UI
import {Button, PasswordRulesTooltip} from '@components';
// OTHER
import AuthenticationWrapper from "@/Views/Authentication/components/AuthenticationWrapper";
import {usePasswordRules} from "@/functions/authenticationFunctions";
import {regexEmail} from "@functions";
// Hooks
import {useAuthentication} from "@/Context/Authentication/AuthenticationProvider";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ForgotPasswordComponent() {

    let query = useQuery();
    const history = useHistory();
    const {t} = useTranslation(["common"])

    const {checkPassword, isPasswordValid, passwordRules} = usePasswordRules()
    const {
        checkLoggedInUser,
        sendForgotPasswordCode,
        resetCredentials: credentials, setResetCredentials: setCredentials,
        resetPassword
    } = useAuthentication()


    const [step, setStep] = useState("email_input");

    useEffect(() => {
        // TODO: This could be moved elsewhere depending on "are clients stoopid?"
        const eid = query.get("eid");
        if (!!eid) history.push("/signup");

        checkLoggedInUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: this behavior is meh. We don't check the code before allowing the next screen -
    //  even if it's secured, we should do it otherwise
    useEffect(() => {
        if (credentials.verificationCode.length < 6) return undefined;
        setStep("password_input");
    }, [credentials]);

    useEffect(() => {
        checkPassword(credentials.password)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials.password])

    // Button actions
    const buttonHandler = () => {

        const handleProcessEmail = () => {
            sendForgotPasswordCode().then((res: any) => {
                if (!!res) setStep("code_input");
            })
        }

        const handleClick = () => {
            switch (step) {
                case "email_input": handleProcessEmail(); break;
                case "code_input": setStep("password_input"); break;
                case "password_input": resetPassword(); break;
                default: return null
            }
        }

        const returnText = () => {
            switch (step) {
                case "email_input": return t("Reset_password_button")
                case "code_input":return t("Reset_password_button")
                case "password_input": return t("Reset_password")
            }
        }

        const disableButton = () => {
            switch (step) {
                case "email_input": return !!credentials.username && regexEmail(credentials.username)
                case "code_input": return !!credentials.verificationCode
                case "password_input": return !!credentials.password && isPasswordValid
                default: return true
            }
        }

        return { handleClick, returnText, disableButton }
    }

    // Renders
    const renderInputs = () => {

        const renderEmailInput = () => {
            return (
                <FormControl fullWidth variant="outlined">
                    <TextField
                        label={t("Mail")}
                        id="email"
                        placeholder={t("Your_mail")}
                        onChange={(e: any) => setCredentials({...credentials, username: e.target.value})}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            )
        }

        const renderVerificationCodeInput = () => {
            return (
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <ReactCodeInput
                            type='text'
                            fields={6}
                            onChange={(code: any) => setCredentials({...credentials, verificationCode: code})}
                            name="verificationCodeInput"
                            inputMode={'latin'}
                        />
                    </Grid>
                </Grid>
            )
        }

        const renderPasswordInput = () => {
            return (
                <Grid container direction="row" spacing={3} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12}>
                        <FormControl sx={{ my: 1 }} fullWidth variant="outlined">
                            <PasswordRulesTooltip {...{passwordRules, t}}>
                                <TextField
                                    label={t("Password")}
                                    type="password"
                                    id="password"
                                    onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                                    placeholder={t("Your_password")}
                                    variant="outlined"
                                    value={credentials.password}
                                    error={Boolean(credentials.password && !isPasswordValid)}
                                    InputProps={{
                                        startAdornment: (
                                            isPasswordValid ?
                                                <InputAdornment position="start">
                                                    <CheckCircle style={{color: "green"}}/>
                                                </InputAdornment>
                                            : !isPasswordValid && credentials.password !== "" ?
                                                <InputAdornment position="start">
                                                    <Cancel style={{color: "red"}}/>
                                                </InputAdornment>
                                            :
                                                <InputAdornment position="start">
                                                    <Lock />
                                                </InputAdornment>
                                        ),
                                    }}
                                />
                            </PasswordRulesTooltip>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }

        switch (step) {
            case "email_input": return renderEmailInput()
            case "code_input": return renderVerificationCodeInput()
            case "password_input": return renderPasswordInput()
            default: return null
        }
    }

    return (
        <AuthenticationWrapper forgotPassword {...{t}}>
            <Typography align={"center"} variant="body1">
                {t("Reset_password_text")}
            </Typography>

            <Box mt={3}>{renderInputs()}</Box>

            <Button
                primary fullWidth type="submit"
                disabled={!Boolean(buttonHandler().disableButton())}
                onClick={() => buttonHandler().handleClick()}
            >
                {buttonHandler().returnText()}
            </Button>

            <Button
                fullWidth secondary text
                component={Link} to="/login"
                startIcon={<ArrowBackIcon/>}
            >
                {t("Back")}
            </Button>
        </AuthenticationWrapper>
    )
}
