// BASE
import React from 'react';
import {BrowserRouter} from "react-router-dom";
// Routes
import UnitagRoutes from "./routes";
// Layout
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
// Stripe
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
// Contexts
import {AuthenticationProvider, DashboardUiProvider, ErrorBoundaryProvider, TourProvider, UserProvider} from "@context";
import {PermissionsProvider} from "@/Context/User/PermissionsProvider";
import Support from "@/layout/Support";
import {ErrorBoundary} from "react-error-boundary";
import CriticalError from "@/layout/CriticalError";

const {REACT_APP_STRIPE_KEY} = process.env;

function App() {
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY!);

    return (

            <ErrorBoundary FallbackComponent={CriticalError}>
                <Elements stripe={stripePromise}>
                    <BrowserRouter>
                        <div className="App">
                            {/* New providers*/}
                            <ErrorBoundaryProvider>
                                <UserProvider>
                                    <PermissionsProvider>
                                        <DashboardUiProvider>
                                            <AuthenticationProvider>
                                                <TourProvider>
                                                    <Navbar>
                                                        <UnitagRoutes />
                                                    </Navbar>
                                                    <Support />
                                                    <Footer/>
                                                </TourProvider>
                                            </AuthenticationProvider>
                                        </DashboardUiProvider>
                                    </PermissionsProvider>
                                </UserProvider>
                            </ErrorBoundaryProvider>

                        </div>
                    </BrowserRouter>
                </Elements>
            </ErrorBoundary>
    );
}

export default App;
