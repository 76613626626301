import React, {useEffect, useState} from "react"
// MUI
import {Business, Flight, RememberMe, RocketLaunch, Send, WorkspacePremium} from "@mui/icons-material";
import {
    Box,
    Container,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery
} from "@mui/material";
// Components
import {CustomTabPanel, customTabPanelProps, LoadingCircle} from "@components";
import {PlansFrequencySwitch, PlansTab} from "@/Views/Plans/components";
import StandaloneTab from "./components/tabs/StandaloneTab";
// Hooks
import {usePermissions} from "@/Context/User/PermissionsProvider";
import {useUser} from "@context";
import {useStyles} from "@/Context/Ui/DashboardUiProvider";
// i18n
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
// Constants
import {
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";

export default function Plans() {

    const {t} = useTranslation('pricing');
    const {theme} = useStyles()
    const {plans, getPlans} = useUser()
    const {trueAccountType, isAccountRole, userRole} = usePermissions()
    const history = useHistory()
    // Breakpoints
    const smallDevice = useMediaQuery(theme.breakpoints.down('lg'));
    const notThatSmallDevice = useMediaQuery(theme.breakpoints.up('sm'));

    const [loading, setLoading] = useState(true);
    // Price data
    const [currency, setCurrency] = useState<any>("usd");
    const [frequency, setFrequency] = useState<string>("monthly")
    // Registered prices
    const [standardPrice, setStandardPrice] = useState<any>({
        monthly: 0,
        yearly: 0,
        basePriceMonthly: 0,
        basePriceYearly: 0
    })
    const [goldPrice, setGoldPrice] = useState<any>({
        monthly: 0,
        yearly: 0,
        basePriceMonthly: 0,
        basePriceYearly: 0
    })
    const [platinumPrice, setPlatinumPrice] = useState<any>({
        monthly: 0,
        yearly: 0,
        basePriceMonthly: 0,
        basePriceYearly: 0
    })
    // VCards
    // These states have been replaced by static data for the slider does not have an utility here
    const vcardOnlyPrice = {monthly: 2.8, yearly: 28.08}
    const vcardQuantity = 1
    // Tabs
    const [tabValue, setTabValue] = useState<number>(0);
    // Animation management
    const [pinned, setPinned] = useState<boolean>(false)

    const styles = {
        wrapper: { pb: 14, pt: 3, px: [1, 3, 5, "100px"] },
        introText: { fontSize: {xs: 35, md: 50}, mt: 7 },
        tabsWrapper: {
            '& .MuiTabs-indicator': {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
            },
            "& .MuiTabs-indicatorSpan": {
                width: "100%",
                backgroundColor: "#8721A1"
            }
        }

    }

    // Subscriptions data
    const subscriptions = {
        plans: [
            {
                label: "Standard",
                type: "standard",
                sublabel: "standard_text",
                icon: <Send />,
                qrcodes: "100",
                analytics: t("2_weeks"),
                link: { pathname: '/alacarte', state: { fromPlan: standardSubscription } },
                price: frequency === "monthly" ? standardPrice.monthly : standardPrice.yearly.toFixed(2),
            },
            {
                label: "Gold",
                type: "gold",
                sublabel: "gold_text",
                icon: <Flight />,
                qrcodes: "500",
                analytics: t("3_months"),
                link: { pathname: '/alacarte', state: { fromPlan: goldSubscription } },
                price: frequency === "monthly" ? goldPrice.monthly : goldPrice.yearly.toFixed(2),
            },
            {
                label: "Platinum",
                type: "platinum",
                sublabel: "platinum_text",
                icon: <RocketLaunch />,
                qrcodes: "unlimited",
                analytics: t("3_years"),
                link: { pathname: '/alacarte', state: { fromPlan: platinumSubscription } },
                price: frequency === "monthly" ? platinumPrice.monthly : platinumPrice.yearly.toFixed(2),
            },
            {
                label: "Enterprise",
                type: "enterprise",
                sublabel: "enterprise_subtext",
                icon: <Business />,
            }
        ],
        standalone: [
            {
                label: "vCard Pro",
                type: "standalone",
                icon: <RememberMe />,
                sublabel: "i_want_standalone",
                link: { pathname: '/alacarte', state: { fromPlan: standaloneSubscription } },
                price: frequency === "monthly" ? (vcardOnlyPrice.monthly * vcardQuantity).toFixed(2) : (vcardOnlyPrice.yearly * vcardQuantity).toFixed(2),
            },
            {
                label: "Enterprise",
                type: "enterprise",
                sublabel: "vcards_enterprise_text",
                buttonLabel: t("common:contact_us"),
                icon: <Business />,
            }
        ]
    }

    // Get plans data (mandatory)
    useEffect(() => {
        if (!plans) getPlans()
        // Scroll to top
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Redirecting to "/alacarte" if already platinum
    // Redirecting to "/" if user can't access upgrade and plans (aka is not owner)
    useEffect(() => {
        if (trueAccountType === platinumSubscription) {
            history.push({
                pathname: "/alacarte",
                state: {
                    fromPlan: platinumSubscription,
                    topUp: true
                }
            })
        } else if (!!userRole && !isAccountRole(["owner"])) {
            history.push("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trueAccountType]);

    // Hydrate plans data
    useEffect(() => {
        if (!!plans && !!plans[0]) {
            setStandardPrice({...plans[0]})
            setGoldPrice({...plans[1]})
            setPlatinumPrice({...plans[2]})
            setCurrency(plans[0].currency)
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans])

    // Reset pinned state
    useEffect(() => {
        if (tabValue === 0) setPinned(false)
    }, [tabValue])

    // Tab management
    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // Tabs
    const renderTabs = () => {

        const tabStyles = {
            minWidth: 200,
            "&.Mui-selected": {
                color: "enterprise.main",
            },
        }

        const renderIntro = () => {
            return (
                <>
                    <Box>
                        <Container maxWidth={"md"}>
                            <Typography
                                variant={"h2"}
                                component={"h1"}
                                align={'center'}
                                fontWeight={700}
                                gutterBottom
                                sx={styles.introText}
                            >
                                {tabValue === 0 ? t("Pricing") : t("standalone_pricing")}
                            </Typography>

                            <Typography variant={"body1"} align={"center"} gutterBottom>
                                {tabValue === 0 ? t("pricing_text") : t("standalone_pricing_text")}
                            </Typography>
                        </Container>
                    </Box>

                    <PlansFrequencySwitch {...{frequency, setFrequency, t}} />
                </>
            )
        }

        return (
            <>
                <Box justifyContent="center" alignItems="center" >
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        centered
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
                        sx={styles.tabsWrapper}
                    >
                        <Tab icon={<WorkspacePremium />} iconPosition={"start"} disableRipple label={t("plans")} sx={tabStyles} {...customTabPanelProps(0)} />
                        <Tab icon={<RememberMe />} iconPosition={"start"} disableRipple label={t("vcard_pro")} sx={tabStyles} {...customTabPanelProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel index={0} value={tabValue}>
                    <Stack spacing={3}>
                        {renderIntro()}
                        <PlansTab
                            {...{
                                pinned, setPinned,
                                setTabValue,
                                smallDevice,
                                subscriptions, frequency, currency,
                                notThatSmallDevice,
                                trueAccountType,
                                t
                            }}
                        />
                    </Stack>
                </CustomTabPanel>

                <CustomTabPanel index={1} value={tabValue}>
                    <Stack spacing={3}>
                        {renderIntro()}
                        <StandaloneTab
                            {...{
                                pinned,
                                setTabValue,
                                subscriptions,
                                smallDevice,
                                frequency, currency,
                                trueAccountType,
                                t
                            }}
                        />
                    </Stack>
                </CustomTabPanel>
            </>
        )
    }

    if (!plans || loading) return <LoadingCircle />

    return (
        <Container maxWidth={"xl"} sx={styles.wrapper}>
            {renderTabs()}
        </Container>
    )
}
