import React, {createContext, useContext, useEffect, useMemo, useState} from "react"
// CSS
import '../../styles/globals.css'
import '../../styles/PrimereactOverrides.css'
// Primereact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// MUI
import {alpha, getContrastRatio} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
// Elastic UI
import '@elastic/eui/dist/eui_theme_light.css';
import {EuiProvider} from "@elastic/eui"
// Hooks
import {useSnackbar} from "@hooks";
import {useUser} from "@context";
// Calls
import {APIGet, APIPostFile, APIPut} from "@api";
// Translation
import {useTranslation} from "react-i18next";


const {REACT_APP_API_URL} = process.env;

// Module declaration
declare module '@mui/material/styles' {
    // noinspection JSUnusedGlobalSymbols
    interface Palette {
        free: Palette['primary']
        gold: Palette['primary']
        standard: Palette['primary']
        platinum: Palette['primary']
        enterprise: Palette["primary"]
        standalone: Palette["primary"]
    }

    // noinspection JSUnusedGlobalSymbols
    interface PaletteOptions {
        free?: PaletteOptions['primary']
        gold?: PaletteOptions['primary']
        standard?: PaletteOptions['primary']
        platinum?: PaletteOptions['primary']
        enterprise?: PaletteOptions['primary']
        standalone?: PaletteOptions["primary"]
    }
}

declare module '@mui/material/Button' {
    // noinspection JSUnusedGlobalSymbols
    interface ButtonPropsColorOverrides {
        free: true
        gold: true
        standard: true
        platinum: true
        enterprise: true
        standalone: true
    }
}

declare module '@mui/material/Typography' {
    // noinspection JSUnusedGlobalSymbols
    interface TypographyPropsColorOverrides {
        free: true
        gold: true
        standard: true
        platinum: true
        enterprise: true
        standalone: true
    }
}

declare module '@mui/icons-material' {
    // noinspection JSUnusedGlobalSymbols
    interface CommonPropsColorOverrides {
        free: true
        gold: true
        standard: true
        platinum: true
        enterprise: true
        standalone: true
    }
}

const colors = {
    primary: "#b4c74a",
    secondary: "#6192bd",
    free: "#adadad",
    standard: "#afc928",
    gold: "#e39d00",
    platinum: "#0077cc",
    enterprise: "#8721a1",
    standalone: "#00cc8f",
}

const stylesInit = {
    primaryColor: colors.primary,
    secondaryColor: colors.secondary,
    logo: "https://unitag-public-assets.s3.eu-west-1.amazonaws.com/website/logos/unitagLogoWhite.svg"
}

// Provider context creation
const StylesContext = createContext<any>(null)

export const useStyles = () => useContext(StylesContext)

const DashboardUiProvider = ({children}: any) => {

    const {handleError, handleSuccess} = useSnackbar()
    const {user} = useUser()
    const {t} = useTranslation(["common", "settings"])
    // states
    const [stylesDidLoad, setStylesDidLoad] = useState<boolean>(false)
    const [initialStyles, setInitialStyles] = useState<any>(stylesInit)
    const [primaryColor, setPrimaryColor] = useState(stylesInit.primaryColor);
    const [secondaryColor, setSecondaryColor] = useState(stylesInit.secondaryColor);
    const [logo, setLogo] = useState<any>(stylesInit.logo);
    // unsaved changes
    let unsavedChanges = ((primaryColor !== initialStyles.primaryColor) || (secondaryColor !== initialStyles.secondaryColor) || (logo !== initialStyles.logo))
    // main theme content
    const theme = useMemo(() => createTheme({
        palette: {
            primary: {
                main: primaryColor,
                contrastText: "#ffffff",
            },
            secondary: {
                main: secondaryColor,
                contrastText: "#ffffff",
            },
            text: {
                secondary: "#525252",
            },
            standard: {
                main: colors.standard,
                light: alpha(colors.standard, 0.5),
                dark: alpha(colors.standard, 0.9),
                contrastText: getContrastRatio(alpha(colors.standard, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
            },
            gold: {
                main: colors.gold,
                light: alpha(colors.gold, 0.5),
                dark: alpha(colors.gold, 0.9),
                contrastText: getContrastRatio(alpha(colors.gold, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
            },
            platinum: {
                main: colors.platinum,
                light: alpha(colors.platinum, 0.5),
                dark: alpha(colors.platinum, 0.9),
                contrastText: getContrastRatio(alpha(colors.platinum, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
            },
            enterprise: {
                main: colors.enterprise,
                light: alpha(colors.enterprise, 0.5),
                dark: alpha(colors.enterprise, 0.9),
                contrastText: getContrastRatio(alpha(colors.enterprise, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
            },
            standalone: {
                main: colors.standalone,
                light: alpha(colors.standalone, 0.5),
                dark: alpha(colors.standalone, 0.9),
                contrastText: getContrastRatio(alpha(colors.standalone, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
            }
        },
        typography: {
            h1: {
                fontFamily: 'Roboto',
                fontSize: 48,
                lineHeight: "58px",
                letterSpacing: "0.00938em",
                fontWeight: 900,
            },
            h2: {
                fontSize: 24,
                lineHeight: '34px',
                fontWeight: 400,
            },
            h5: {
                fontWeight: '500',
            }
        },
    }), [primaryColor, secondaryColor])

    useEffect(() => {
        if (!!user && !stylesDidLoad) fetchUserCustomStyles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // fetch user/organisation data
    const fetchUserCustomStyles = () => {
        APIGet(REACT_APP_API_URL + "/user/setting").then((res: any) => {
            if (!!res.parsedBody && !!res.parsedBody.dash_design) {
                let _styles = res.parsedBody.dash_design
                setInitialStyles(_styles)
                setPrimaryColor(_styles.primaryColor)
                setSecondaryColor(_styles.secondaryColor)
                setLogo(_styles.logo)
            }
        }).catch(() => {
            handleError(t("settings:error_fetching_styles"))
        }).finally(() => {
            setStylesDidLoad(true)
        })
    }

    const saveUserCustomStyles = () => {
        let newStyles = {...{primaryColor, secondaryColor, logo}}

        let body = {dash_design: newStyles}

        APIPut(REACT_APP_API_URL + "/user/setting", body).then(() => {
            setInitialStyles(newStyles)
            handleSuccess(t("settings:success_saving_styles"))
        }).catch(() => {
            handleError(t("settings:error_saving_styles"))
        })
    }

    // Logo import and saving
    const handleSaveLogo = async (data: any) => {
        const file = data.target.files[0]

        await APIPostFile<any>(REACT_APP_API_URL + `/asset/upload/logo`, file)
            .then((data) => {
                    if (data.status === 200) {
                        setLogo(`https://unitag-assets.s3.eu-west-1.amazonaws.com/logo/${data.parsedBody.asset_id}`)
                    } else handleError(t("settings:error_upload_image"))
                }, () => handleError(t("settings:error_upload_image"))
            ).finally(() => data.target.value = null)
    }

    return (
        <StylesContext.Provider value={{
            useStyles, theme,
            primaryColor, setPrimaryColor,
            secondaryColor, setSecondaryColor,
            logo, setLogo, handleSaveLogo,
            saveUserCustomStyles, unsavedChanges
        }}>
            <ThemeProvider theme={theme}>
                <EuiProvider>
                    {children}
                </EuiProvider>
            </ThemeProvider>
        </StylesContext.Provider>
    )
}

export default DashboardUiProvider