import React from "react"
import {FormControl, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {regexEmail} from "@functions";
import {AccountCircle} from "@mui/icons-material";
import {checkSsoSignupToLogin} from "@/functions/authenticationFunctions";
import {Button} from "@components";

export default function SignupFormEmailCorrection(props: any) {

    const {
        credentials, setCredentials,
        loading, signup,
        t
    } = props

    const emailOnChange = (e: any) => {
        setCredentials({...credentials, username: e.target.value})
        checkSsoSignupToLogin(e.target.value)
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography align={"center"}>{t("email_already_used")}</Typography>
            </Grid>

            <FormControl sx={{ my: 1 }} fullWidth variant="outlined">
                <TextField
                    label={t("Mail")}
                    id="email"
                    placeholder={t("Your_mail")}
                    onChange={emailOnChange}
                    variant="outlined"
                    error={Boolean(!regexEmail(credentials.username))}
                    value={credentials.username}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle/>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>

            <Grid item xs={12}>
                <Button primary fullWidth disabled={loading} onClick={signup}>
                    {t("complete_signup")}
                </Button>
            </Grid>
        </>
    )
}