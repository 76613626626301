import React, {useState} from "react"
import {Link as RouterLink} from "react-router-dom"
// MUI
import {
    Box,
    FormControl,
    Grid, InputAdornment,
    Link,
    TextField, Divider, FormControlLabel, Checkbox, FormGroup, Dialog, Button
} from "@mui/material";
import {AccountCircle, Cancel, CheckCircle, Lock} from "@mui/icons-material";
// RECAPTCHA
import Recaptcha from "react-recaptcha";
// Regexes
import {regexEmail} from "@functions";
import {checkSsoSignupToLogin} from "@/functions/authenticationFunctions";
import {PasswordRulesTooltip} from "@components";
import {SignupButton} from "@components/authentication";
import {Trans} from "react-i18next";
import TermsAndConditionsContent from "@components/Legal/TermsAndConditionsContent";

const {REACT_APP_CAPTCHA_PUBLIC_KEY,} = process.env;

export default function SignupFormGeneral(props: any) {

    const {
        credentials, setCredentials,
        isPasswordValid, passwordRules,
        verifiedRecaptcha, setVerifiedRecaptcha,
        product,
        handleSignupStep,
        loading,
        t
    } = props

    const [openLegalDialog, setOpenLegalDialog] = useState<boolean>(false)

    // CHECK SSO ON EMAIL CHANGE
    const emailOnChange = (e: any) => {
        setCredentials({...credentials, username: e.target.value})
        checkSsoSignupToLogin(e.target.value)
    }

    // Change checkboxes value
    const handleCredentialsChecksSwitch = (attr: any) => {
        setCredentials({...credentials, attributes: {
            ...credentials.attributes,
            [attr]: !credentials.attributes[attr]
        }})
    }

    // renders
    const renderFormContent = () => {
        return (
            <>
                {/* EMAIL */}
                <FormControl sx={{ my: 1 }} fullWidth variant="outlined">
                    <TextField
                        label={t("Mail")}
                        id="email"
                        placeholder={t("Your_mail")}
                        onChange={emailOnChange}
                        variant="outlined"
                        error={Boolean(!regexEmail(credentials.username))}
                        value={credentials.username}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                {/* PASSWORD */}
                <FormControl sx={{ my: 1 }} fullWidth variant="outlined">
                    <PasswordRulesTooltip {...{passwordRules, t}}>
                        <TextField
                            label={t("Password")}
                            type="password"
                            id="password"
                            onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                            placeholder={t("Your_password")}
                            variant="outlined"
                            value={credentials.password}
                            error={Boolean(credentials.password && !isPasswordValid)}
                            InputProps={{
                                startAdornment: (
                                    isPasswordValid ?
                                        <InputAdornment position="start">
                                            <CheckCircle style={{color: "green"}}/>
                                        </InputAdornment>

                                        : !isPasswordValid && credentials.password !== "" ?
                                            <InputAdornment position="start">
                                                <Cancel style={{color: "red"}}/>
                                            </InputAdornment>
                                            :
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                ),
                            }}
                        />
                    </PasswordRulesTooltip>
                </FormControl>
                {/* PASSWORD CONF */}
                <FormControl sx={{ my: 1 }} fullWidth variant="outlined">
                    <TextField
                        label={t("Confirm_password")}
                        type="password"
                        id="passwordConfirmation"
                        onChange={(e) => setCredentials({...credentials, passwordConf: e.target.value})}
                        placeholder={t("Confirm_password")}
                        variant="outlined"
                        value={credentials.passwordConf}
                        InputProps={{
                            startAdornment: (
                                !!credentials.passwordConf && (credentials.password === credentials.passwordConf) ?
                                    <InputAdornment position="start">
                                        <CheckCircle style={{color: "green"}}/>
                                    </InputAdornment>
                                    : !!credentials.passwordConf && (credentials.password !== credentials.passwordConf) ?
                                        <InputAdornment position="start">
                                            <Cancel style={{color: "red"}}/>
                                        </InputAdornment>
                                        :
                                        <InputAdornment position="start">
                                            <Lock />
                                        </InputAdornment>
                            )
                        }}
                    />
                </FormControl>

                <FormGroup>
                    <FormControlLabel
                        required
                        sx={{ mb: "-4px" }}
                        componentsProps={{typography: { variant: "body2", fontSize: 12, align: "justify" }}}
                        control={<Checkbox size={"small"}
                            checked={credentials.attributes["custom:accepted_terms"]}
                            onChange={() => handleCredentialsChecksSwitch("custom:accepted_terms")}
                        />}
                        label={
                            <Trans t={t} i18nKey={"accept_terms"}>
                                e<span
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setOpenLegalDialog(true);
                                    }}
                                    className={"link"}
                                    style={{ color: "#6192bd"}}
                                >e</span>e
                            </Trans>
                        }
                    />

                    <FormControlLabel
                        componentsProps={{typography: { variant: "body2", fontSize: 12, align: "justify" }}}
                        control={<Checkbox size={"small"}
                            checked={credentials.attributes["custom:accepted_newsletter"]}
                            onChange={() => handleCredentialsChecksSwitch("custom:accepted_newsletter")}
                        />}
                        label={t("accept_newsletter")}
                    />
                </FormGroup>
            </>
        )
    }

    const renderRedirections = () => {
        return (
            <>
                <Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "left" }}>
                    <Link component={RouterLink} to={`/login${product ? "?product=" + product : ''}`} color="secondary">
                        {t("Already_have_an_account")}
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "right" }}>
                    <Link component={RouterLink} to="/forgot_password" color="secondary">
                        {t("Forgot_password")}
                    </Link>
                </Grid>
            </>
        )
    }

    const renderLegalDialog = () => {
        return (
            <Dialog open={openLegalDialog} maxWidth={"md"} fullWidth onClose={() => setOpenLegalDialog(false)}>
                <div style={{margin: "auto"}}>
                    <Button onClick={() => setOpenLegalDialog(false)} sx={{mt: 3}} variant={"outlined"}
                            color={"secondary"}>
                        {t("common:Back")}
                    </Button>
                </div>
                <TermsAndConditionsContent/>
                <div style={{margin: "auto"}}>
                    <Button onClick={() => setOpenLegalDialog(false)} sx={{mb: 3}} variant={"outlined"}
                            color={"secondary"}>
                        {t("common:Back")}
                    </Button>
                </div>
            </Dialog>
        )
    }


    return (
        <Box>
            {renderFormContent()}

            <Grid container justifyContent={{ xs: "center", md: "space-between" }} rowSpacing={2} mt={1}>
                <Grid item xs={12}>
                    <Recaptcha
                        sitekey={REACT_APP_CAPTCHA_PUBLIC_KEY!}
                        render="explicit"
                        verifyCallback={() => setVerifiedRecaptcha(true)}
                    />
                </Grid>

                <Grid item xs={12}><Divider /></Grid>

                {renderRedirections()}

                <Grid item xs={12}>
                    <SignupButton
                        {...{
                            credentials, isPasswordValid,
                            loading,
                            handleSignupStep,
                            verifiedRecaptcha,
                            t
                        }}
                    />
                </Grid>
            </Grid>

            {renderLegalDialog()}
        </Box>
    );
}
