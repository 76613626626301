import {Container, Divider, Paper, Typography} from "@mui/material";
import i18next from "i18next";
import {Trans, useTranslation} from "react-i18next";
import React from "react";


export default function TermsAndConditionsContent() {

    const {t} = useTranslation(['terms', 'common']);

    return (
        <>
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Paper variant="outlined" sx={{ p: 7, my: 5 }}>

                    <Typography variant="h2" component="h1" style={{ margin: "16px 0px" }}>
                        {t("terms_and_conditions")}
                    </Typography>

                    <Divider sx={{ my: 3 }} />

                    { i18next.language === "fr-FR" ? (
                            <>
                                <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                                    {t("art_1")}
                                </Typography>

                                <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                                    {t("1_1")}
                                </Typography>

                                <ul>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_1">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_2">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_3">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_4">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_5">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_6">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_7">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_8">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_9">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_10">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_11">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_12">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_13">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_14">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_15">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_16">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_17">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_18">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_19">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_20">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_21">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans t={t} i18nKey="1_1_22">
                                            <b>e</b>e.
                                        </Trans>
                                    </li>
                                </ul>
                            </>
                        ) :
                        <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                            {t("en_pretext")}
                        </Typography>
                    }

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("2_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("2_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("2_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("2_4")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_3")}
                    </Typography>

                    <Typography variant="h6" color="primary" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("art_31")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("31_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("31_2")}
                    </Typography>

                    <ul>
                        <li>{t("31_21")}</li>
                        <li>{t("31_22")}</li>
                        <li>{t("31_23")}</li>
                        <li>{t("31_24")}</li>
                    </ul>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("31_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("31_4")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("31_5")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("31_6")}
                    </Typography>

                    <Typography variant="h6" color="primary" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("art_33")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("32_2")}
                    </Typography>


                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("32_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("32_4")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_4")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1")}
                    </Typography>

                    <Typography variant="h6" color="primary" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("art_41")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("4_1_2")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("4_1_4")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1_5")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1_6")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("4_1_7")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1_8")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1_9")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("4_1_10")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_1_11")}
                    </Typography>

                    <Typography variant="h6" color="primary" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("art_42")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_2_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("4_2_2")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_5")}
                    </Typography>

                    <Typography variant="h6" color="primary" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("art_51")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("5_1_1")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_1_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_1_3")}
                    </Typography>

                    <Typography variant="h6" color="primary" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("art_52")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_4")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_5")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_6")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_7")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_2_8")}
                    </Typography>

                    <Typography variant="h6" color="primary" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("art_53")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_3_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_3_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_3_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_3_4")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("5_3_5")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_6")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("6_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("6_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("6_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("6_4")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("6_5")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("6_6")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("6_7")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_7")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("7_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("7_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("7_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("7_4")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_8")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_4")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_5")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_6")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_7")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_8")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_9")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_10")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("8_11")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_9")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("9_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("9_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("9_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("9_4")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_10")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("10_1")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_11")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_2")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_3")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_4")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_5")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_6")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_7")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_8")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_9")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_10")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_11")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_12")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_13")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("11_14")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_12")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("12_1")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_13")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("13_1")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("13_2")}
                    </Typography>

                    <Typography variant="h4" component="h2" color="primary" style={{ margin: "16px 0px 16px 0px" }}>
                        {t("art_14")}
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        <b>{t("14_1")}</b>
                    </Typography>

                    <Typography variant="body1" component="p" style={{ margin: "8px 0px 8px 0px"}}>
                        {t("14_2")}
                    </Typography>

                </Paper>
            </Container>
        </>
    )
}