import {Box, Button, ButtonBase, Collapse, IconButton, Skeleton, Stack, Typography} from "@mui/material";
import {ChevronRight, Flight, RememberMeOutlined, RocketLaunch, Send} from "@mui/icons-material";
import React from "react";
import {formatPrice, roundToCent} from "@functions";
import {usePermissions} from "@/Context/User/PermissionsProvider";
import {Link} from "react-router-dom";

function StandaloneContent(props: any) {

    const {pinned, smallDevice, onTab, t} = props

    const renderPlanTitle = () => {
        return (
            <Stack>
                <Typography variant={"h5"} component={"h2"} fontWeight={700}>{t("vcard_title")}</Typography>
            </Stack>
        )
    }

    const renderPlanDescription = () => {
        return (
            <Collapse in={!pinned && !smallDevice}>
                <Typography variant={"body2"}>{t("standalone_text")}</Typography>
            </Collapse>
        )
    }

    const renderStandaloneCTA = () => {
        return (
            <ButtonBase onClick={onTab} sx={{
                my: pinned && !smallDevice ? 0 : 2,
                borderRadius: 2,
                transition: ".2s ease-in-out",
                justifySelf: "stretch",

                width: "100%",
                textAlign: "left",
            }}>
                <Box sx={{
                    background: "linear-gradient(18deg, rgba(9,9,121,0.3) 0%, rgba(0,255,186,1) 99%)",
                    p: 2,
                    justifySelf: "stretch",
                    alignContent: "end",
                    borderRadius: 2,
                    height: "100%",
                    width: "100%",
                    transition: ".2s ease-in-out",
                    "&:hover": { background: "linear-gradient(18deg, rgba(9,9,121,0.3) 0%, rgba(0,255,186,1) 77%), rgba(0,0,0,0.2)" }
                }}>
                    <Collapse in={!pinned}>
                        <RememberMeOutlined sx={{ fill: "white", height: 30, width: 30 }} />
                    </Collapse>
                    <Typography variant={"h5"} component={"h3"} fontWeight={700} color={"white"}>vCard Pro</Typography>
                    <Typography color={"white"}>{t("view_offers")} <ChevronRight fontSize={"inherit"} sx={{ mb: "-3px"}} /></Typography>
                </Box>
            </ButtonBase>
        )
    }

    const renderBottomLine = () => {
        return (
            <Collapse in={!pinned}>
                <Box sx={{
                    alignSelf: "flex-end!important",
                }}>
                    <Typography fontSize={13}>{t("standalone_bottom_text")}</Typography>
                    <a
                        href={"https://unitag.io/contact"}
                        rel={"noreferrer"}
                        target={"_blank"}
                    >
                        <Typography fontSize={13} className={"link"}>{t("standalone_bottom_cta")}</Typography>
                    </a>
                </Box>
            </Collapse>
        )
    }

    return (
        <>
            <Box
                minHeight={pinned ? 175 : 400}
                display={"grid"}
                sx={{
                    transition: "min-height 0.5s ease-in-out",
                    gridTemplateRows: "min-content auto max-content"
                }}
            >
                <Box>
                    {renderPlanTitle()}
                    {renderPlanDescription()}
                </Box>
                {renderStandaloneCTA()}
                {renderBottomLine()}
            </Box>
        </>
    )
}

function WantMoreContent(props: any) {

    const {onTab, t} = props

    const renderPlanTitle = () => {
        return (
            <Stack>
                <Typography variant={"h5"} component={"p"} fontWeight={700}>{t("back_to_plans_title")}</Typography>
            </Stack>
        )
    }

    const renderPlanDescription = () => {
        return (
            <Typography variant={"body2"} sx={{ mt: 3 }}>{t("back_to_plans_text")}</Typography>
        )
    }

    const renderStandaloneCTA = () => {

        const renderBackToPlanIcons = (backPlan: any) => {
            const { icon } = backPlan
            return (
                <Box
                    // size={"large"}
                    sx={{
                        pointerEvents: "none",
                        borderRadius: 1,
                        backgroundColor: "transparent",
                        color: "white",
                        mr: 1
                    }}
                >
                    {icon}
                </Box>
            )
        }

        const backPlans = [
            { icon: <Send />, color: "standard.main" },
            { icon: <Flight />, color: "gold.main" },
            { icon: <RocketLaunch />, color: "platinum.main" },
        ]

        return (
            <ButtonBase onClick={onTab} sx={{
                mt: 2,
                borderRadius: 1,
                transition: ".2s ease-in-out",
                display: "flex",
                flexDireciton: "row",
                width: "100%",
                textAlign: "left",
                "&:hover": {
                    backgroundColor: "transparent",
                    "& .plansIcons": {
                        opacity: "1!important"
                    }
                }
            }}>
                <Box sx={{
                    background: "linear-gradient(18deg, rgba(153,206,243,0.98) 0%, rgba(89,153,204, 1) 99%)",
                    backgroundColor: "rgba(93,136,170,0.98)",
                    p: 2,
                    border: "none",
                    borderRadius: 1,
                    width: "100%",
                    height: "100%",
                    alignContent: "end",
                    transition: ".2s ease-in-out",
                    "&:hover": {
                        background: "linear-gradient(18deg, rgba(89,153,204, 1) 0%, rgba(93,136,170,0.98) 99%)",
                    }

                }}>
                    <Box display="flex" justifyContent="flex-start">
                        {backPlans.map((item: any, i: number) => (
                            <Box key={i} sx={{ mr: "3px", opacity: 0.5, transition: ".2s ease-in-out" }} className={"plansIcons"}>{renderBackToPlanIcons(item)}</Box>
                        ))}
                    </Box>
                    <Typography variant={"h5"} component={"h3"} fontWeight={700} color={"white"}>{t("back_to_plans")}</Typography>
                    <Typography color={"white"} gutterBottom>{t("view_offers")} <ChevronRight fontSize={"inherit"} sx={{ mb: "-3px"}} /></Typography>
                </Box>
            </ButtonBase>
        )
    }

    return (
        <>
            <Box minHeight={400} display={"grid"} sx={{ transition: "min-height 0.5s ease-in-out",
                gridTemplateRows: "min-content auto max-content" }}>
                <Box>
                    {renderPlanTitle()}
                    {renderPlanDescription()}
                </Box>
                {renderStandaloneCTA()}

            </Box>
        </>
    )
}

function PlanContent(props: any) {

    const {plan, frequency, currency, enterprise, pinned, trueAccountType, vCard, t} = props

    const {returnTierList} = usePermissions()

    const renderPlanIcon = () => {
        return (
            <Collapse in={!pinned}>
                <IconButton
                    // size={"large"}
                    sx={{
                        pointerEvents: "none",
                        borderRadius: 1,
                        backgroundColor: vCard ? "standalone.main" : enterprise ? "#ae3eca" : plan.label.toLowerCase() + ".main",
                        color: "white"
                    }}
                >
                    {plan.icon}
                </IconButton>
            </Collapse>
        )
    }

    const renderPlanTitle = () => {
        return (
            <>
                <Typography
                    variant={"body1"}
                    component={"h2"}
                    fontWeight={700}
                    mt={pinned ? 0 : 3}
                    fontSize={16}
                    color={enterprise ? "white" : "black"}
                    sx={{ transition: "margin 0.5s ease-in-out" }}
                >{plan.label.toUpperCase()}</Typography>
            </>
        )
    }

    const renderPlanPricing = () => {

        if (enterprise) return <Typography variant={"h5"} component={"h2"} color={"white"} my={pinned ? 1 : 3} fontWeight={700}>{t("get_a_quote")}</Typography>

        return (
            <Stack sx={{ my: pinned ? 1 : 3, transition: "margin 0.5s ease-in-out" }}>
                { !plan || plan.price === 0 ? <Skeleton variant={"text"} sx={{ fontSize: 21 }} /> :
                    <Typography variant={"h5"} component={"h3"} fontWeight={700}>
                        {vCard && <span style={{fontWeight: 300, fontSize: 12}}>{t("starting_from") + " "}</span>}
                        {formatPrice(plan.price, currency)}&nbsp;
                        <span style={{fontWeight: 300, fontSize: 12 }}>
                            {t("without_vat")}
                        </span>
                    </Typography>
                }
                <Typography variant={"body2"}>* {t(frequency)} {frequency === "yearly" &&
                    <>
                        ({formatPrice(roundToCent(plan.price/12), currency)}/{t("month")})
                    </>}
                </Typography>
            </Stack>
        )
    }

    const renderPlanDescription = () => {
        return (
            <Collapse in={!pinned}>
                {
                    (!!plan.qrcodes && !!plan.analytics) &&
                    <>
                        <Typography variant={"body2"}>{plan.qrcodes === "unlimited" ? <b>{t("unlimited_qr_codes")}</b> : <><b>{plan.qrcodes}</b> QR Codes</>}</Typography>
                        <Typography variant={"body2"}><b>{plan.analytics}</b> {t("of_analytics")}</Typography>

                    </>
                }
                <br/>
                <Typography variant={"body2"} color={enterprise ? "white" : "#535353"}>{t(plan.sublabel)}</Typography>
            </Collapse>
        )
    }

    const renderPlanCTA = () => {

        if (enterprise) {
            return (
                <>
                    <Button
                        fullWidth
                        variant={plan.hightlight ? "contained" : "outlined"}
                        disableElevation
                        component={"a"}
                        href={"https://unitag.io/contact"}
                        // onClick={plan.onClick}
                        disabled={plan.disableClick}
                        sx={{
                            borderColor: "white",
                            color: "white",
                            alignSelf: "flex-end!important",
                            transition: " all 0.2s ease-in-out, margin 0.5s ease-in-out,",
                            "&:hover": {
                                backgroundColor: "white!important",
                                color: "enterprise.main",
                                borderColor: "white!important",
                            }
                        }}
                    >
                        {t("customize_your_plan")}
                    </Button>
                </>
            )
        } else {
            return (
                <Button
                    fullWidth
                    variant={plan.hightlight ? "contained" : "outlined"}
                    disableElevation
                    component={Link}
                    to={plan.link}
                    disabled={plan.disableClick || !!returnTierList(trueAccountType, plan.type.toLowerCase())}
                    sx={{
                        borderColor: enterprise ? "white" : "black",
                        color: enterprise ? "white" : "black",
                        alignSelf: "flex-end!important",
                        transition: " all 0.2s ease-in-out, margin 0.5s ease-in-out,",
                        "&:hover": {
                            backgroundColor: vCard ? "standalone.main" : plan.label.toLowerCase() + ".main",
                            color: "white",
                            borderColor: vCard ? "standalone.main" : plan.label.toLowerCase() + ".main",
                        }
                    }}
                >
                    {
                        returnTierList(trueAccountType, plan.type.toLowerCase()) === "up" ? t("you_got_more")
                        : returnTierList(trueAccountType, plan.type.toLowerCase()) ? t("you_got_this")
                        : plan.buttonLabel ? plan.buttonLabel
                        : t("try_it_free")
                    }
                </Button>
            )
        }
    }

    return (
        <Box minHeight={pinned ? 175 : 400} display={"grid"} sx={{ transition: "min-height 0.5s ease-in-out"}}>
            <Box>
                {renderPlanIcon()}
                {renderPlanTitle()}
                {renderPlanPricing()}
                {renderPlanDescription()}
            </Box>
            {renderPlanCTA()}
        </Box>
    )
}

export { PlanContent, StandaloneContent, WantMoreContent }