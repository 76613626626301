import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// TRANSLATION
import {useTranslation} from 'react-i18next';
// UI Wrapper
import AuthenticationWrapper from "@/Views/Authentication/components/AuthenticationWrapper";
// Hooks
import {usePasswordRules} from "@/functions/authenticationFunctions";
// Components
import {
    SignupFormCodeValidation, SignupFormEmailCorrection,
    SignupFormGeneral,
    SignupFormUserProfile
} from "@/Views/Authentication/Signup/components";
import {useAuthentication} from "@/Context/Authentication/AuthenticationProvider";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Watch out!
// Used "credentials, setCredentials" in this file refers to "signupCredentials, setSignupCredentials" from
// useAuthentication() hook, and not "credentials" from useUser() hook
export default function SignupComponent() {

    const {t} = useTranslation(['common']);
    const query = useQuery();
    const {checkPassword, isPasswordValid, passwordRules} = usePasswordRules()
    const {
        checkLoggedInUser,
        signupCredentials: credentials, setSignupCredentials: setCredentials,
        signup,
        signupStep, setSignupStep, handleSignupStep,
        signIn, verifyCode,
    } = useAuthentication()

    // State management
    const [loading, setLoading] = useState<boolean>(false);

    // Code
    const [verifCode, setVerifCode] = useState<any>('');
    // Captcha
    const [verifiedRecaptcha, setVerifiedRecaptcha] = useState<boolean>(true);
    // Redirection following URL params
    const [product, setProduct] = useState<null | string>('');
    const [vcardsNumber, setVcardsNumber] = useState<number>(0);

    // Check if user is on a plan funnel or general funnel
    const isPlanFunnel = !!product && (product === "standard" || product === "gold" || product === "platinum" || product === "standalone");

    useEffect(() => {
        // Check if any user is connected already - redirects on response
        checkLoggedInUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Prefilling email and creating localstorage for invitations in the case where
    // user comes from a mail invitation
    useEffect(() => {
        if (!!query) {
            const eid = query.get("eid");
            if (!!eid && !credentials.username) {
                // Also prefill credentials
                setCredentials({...credentials, username: eid})
            }

            // Invitation ID for joining organisation
            const iid = query.get("iid");
            if (!!iid) window.localStorage.setItem("iid", iid);
            // Organisation ID
            const oid = query.get("oid");
            if (!!oid) window.localStorage.setItem("oid", oid);

            // Product
            const followedProduct = query.get("product");
            setProduct(followedProduct);
            // Comes from the website and has a vCard number
            const _vcardsNumber= query.get("vcards-number")
            if (!!_vcardsNumber) setVcardsNumber(Number(_vcardsNumber));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
       checkPassword(credentials.password)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials.password])

    useEffect(() => {
        // Include product in funnel on detection
        if (!!product) {
            setCredentials({
                ...credentials, attributes: {
                    ...credentials.attributes,
                    "custom:product_funnel": String(product)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    const handleCodeVerification = () => {
        setLoading(true)
        verifyCode(verifCode).then((res: any) => {
            if (!!res) signIn(product, vcardsNumber)
            else setLoading(false)
        }, () => setLoading(false))
    }

    const handleCodeChange = (code: string) => {
        setVerifCode(code);
    }

    const handleSignup = () => {
        setLoading(true)
        signup().finally(() => {
            setLoading(false)
        })
    }

    // Render steps and content
    const signupStepSwitch = () => {
        if (signupStep === "code") {
            return <SignupFormCodeValidation{...{handleCodeChange, handleCodeVerification, loading, t}} email={credentials.username} />;
        } else if (signupStep === "info") {
            return <SignupFormUserProfile signup={() => handleSignup()} {...{credentials, setCredentials, loading, setSignupStep, product, t}} />
        } else if (signupStep === "email-taken") {
            return <SignupFormEmailCorrection signup={() => handleSignup()} {...{credentials, setCredentials, loading, t}} />
        } else {
            return <SignupFormGeneral
                signup={() => handleSignup()}
                {...{
                    credentials, setCredentials,
                    isPasswordValid, passwordRules,
                    setVerifiedRecaptcha,
                    loading, verifiedRecaptcha,
                    signupStep, handleSignupStep,
                    product,
                    t
                }}
            />;
        }
    }

    return (
        <main>
            <AuthenticationWrapper signup {...{isPlanFunnel, product, credentials, setCredentials, t}}>
                {signupStepSwitch()}
            </AuthenticationWrapper>
        </main>
    )
}
