import React from "react"
import {Link} from "@mui/material";
import {sanitizeUrl} from "@functions";

export default function AuthenticationUnsecuredPage() {
    return (
        <div>
            <h1 style={{color: 'red'}}>
                If you see this page, the Unitag page you're trying to access is under Clickjacking security attack.
            </h1>
            <h2>
                Please inform the support team with the reference of the application from where you clicked this link.
            </h2>
            <h2>
                Click <Link href={sanitizeUrl(window.self.location.href)} title='Unitag console login'
                            target='blank'>here</Link> to
                access the Unitag console safely.
            </h2>
        </div>
    )
}