import React from "react"
import {Tooltip, Typography} from "@mui/material";

export default function PasswordRulesTooltip(props: any) {

    const {placement, children, passwordRules, t} = props

    const renderPasswordRulesTooltipContent = () => {
        const rules = [
            { key: "Pwd_1_lowercase", prop: passwordRules.lowercase },
            { key: "Pwd_8_characters", prop: passwordRules.length },
            { key: "Pwd_1_uppercase", prop: passwordRules.uppercase },
            { key: "Pwd_1_number", prop: passwordRules.number },
            { key: "Pwd_1_special", prop: passwordRules.characters },
        ];

        return (
            <>
                <Typography fontSize={14}>
                    {t("Pwd_rules")}
                </Typography>
                {rules.map((rule: any, index: number) => {
                    return (
                        <Typography fontSize={14} key={index} color={rule.prop ? "primary" : "inherit"}>
                            {t(rule.key)}
                        </Typography>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <Tooltip title={renderPasswordRulesTooltipContent()} placement={!!placement ? placement : "left"} arrow>
                {children}
            </Tooltip>
        </>
    )
}