import {Box, Chip, Grid, Stack, Switch, Tooltip, Typography} from "@mui/material";
import React from "react";

const PlansFrequencySwitch = (props: any) => {

    const {frequency, setFrequency, dense, t} = props

    const styles = {
        typographyLeft: {mt: dense ? "2px" : 1, pr: 1},
        typographyRight: {mt: dense ? "2px" : 1, pl: 1, pr: 1}
    }

    return (
        <Grid item xs={12} mt={dense ? 0 : 5}>
            <Stack direction={"row"} justifyContent={"center"}>
                <Typography sx={styles.typographyLeft} fontWeight={600} variant={dense ? "body2" : "body1"}>
                    {t("pricing:monthly")}
                </Typography>
                <Switch
                    size={dense ? "small" : "medium"}
                    checked={frequency === "yearly"}
                    onChange={() => {
                        if (frequency === "monthly") setFrequency("yearly")
                        else setFrequency("monthly")
                    }}
                />
                <Typography sx={styles.typographyRight} fontWeight={600} variant={dense ? "body2" : "body1"}>
                    {t("pricing:yearly")}
                </Typography>
                <Box mt={dense ? 0 : "4px"}>
                    <Tooltip title={t("pricing:yearly_discount")} placement={"top"} arrow>
                        <Chip label={"-10%"} color={"secondary"} size={"small"}/>
                    </Tooltip>
                </Box>
            </Stack>
        </Grid>
    )
}

export default PlansFrequencySwitch
