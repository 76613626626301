import {Box, Grid, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useIsIntersecting} from "@hooks";
import {PlanContent, PlanPaper, StandaloneContent} from "@/Views/Plans/components/PlansDisplay";
import {PlansDetails, PlansDetailsAccordion} from "@/Views/Plans/components/PlansDetails";

function PlansTab(props: any) {

    const {
        pinned, setPinned,
        setTabValue,
        smallDevice,
        subscriptions, frequency, currency,
        notThatSmallDevice,
        trueAccountType,
        t
    } = props

    // Ref & hooks
    const plansWrapper = useRef(null)
    const intersect = useIsIntersecting(plansWrapper)

    const [animationTimeout, setAnimationTimeout] = useState<boolean>(false)

    const styles = {
        backgroundColor: { backgroundColor: "#f7f8fc" },
        columnsPinnableWrapper: {
            position: smallDevice ? "relative" : "sticky",
            top: pinned ? "-3px" : "32px",
            zIndex: "15!important",
            transition: "top .3s ease-in-out"
        },
        columnsWrapper: {
            top: pinned ? "74px" : "40px",
            position: "relative",
            backgroundColor: "transparent",
            transition: "0.2s ease-in-out"
        },
        // May not work as smoothly on the console
        detailsIndicatorWrapper: { pt: 10, zIndex: 2 },
        detailsIndicator: {
            position: "sticky!important",
            top: "63px",
            zIndex: "15!important", width: "100%!important",
            borderRadius: "0 0 8px 8px", py: 1,
            backgroundColor: "#fcfcfc",
            borderTop: "1px solid",
            borderColor: "divider"
        }
    }

    useEffect(() => {
        if (animationTimeout) return
        if (window.scrollY > 150 && !smallDevice) {
            setAnimationTimeout(true)
            setPinned(!intersect)
            setTimeout(() => {
                setAnimationTimeout(false)
            }, 1000)
        }
        else {
            setAnimationTimeout(true)
            setPinned(false)
            setTimeout(() => {
                setAnimationTimeout(false)
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intersect])

    useEffect(() => {
        if (!!window.scrollY && window.scrollY > 440 && !pinned && !smallDevice) setPinned(true)
        else if (!!window.scrollY && window.scrollY < 400 && pinned) setPinned(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.scrollY])

    const renderPlans = () => {

        const renderVCardsColumn = () => {
            return (
                <>
                    <Grid item xs={14} sm={7} md={5} lg={3} sx={styles.backgroundColor}>
                        <PlanPaper {...{pinned, t}}>
                            <StandaloneContent onTab={() => setTabValue(1)} {...{pinned, t}} />
                        </PlanPaper>
                    </Grid>
                </>
            )
        }

        const renderPlansList = () => {
            return (
                <>
                    {
                        subscriptions.plans.map((plan: any, i: number) => {
                            return (
                                <Grid item xs={14} sm={7} md={5} lg={3} key={i} sx={styles.backgroundColor}>
                                    <PlanPaper
                                        {...{pinned, t}}
                                        popular={plan.label === "Platinum"}
                                        enterprise={plan.label === "Enterprise"}
                                        color={plan.label.toLowerCase() + ".main"}
                                    >
                                        <PlanContent
                                            enterprise={plan.label === "Enterprise"}
                                            {...{plan, frequency, currency, pinned, trueAccountType, t}}
                                        />
                                    </PlanPaper>
                                </Grid>
                            )
                        })
                    }
                </>
            )
        }

        return (
            <>
                <Box sx={styles.columnsPinnableWrapper}>
                    <Box sx={styles.columnsWrapper}>
                        <Grid container columns={15} spacing={1} justifyContent={"center"}>
                            {renderVCardsColumn()}
                            {renderPlansList()}
                        </Grid>
                    </Box>
                </Box>
            </>
        )
    }

    const renderPlansDetails = () => {

        const details = PlansDetails(t)

        const renderDetailsIndicator = () => {
            if (!(smallDevice && notThatSmallDevice)) return <></>
            return (
                <>
                    <Box sx={styles.detailsIndicator}>
                        <Grid container>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}><Typography fontWeight={600} align={"center"} color={"standard.main"}>Standard</Typography></Grid>
                            <Grid item xs={3}><Typography fontWeight={600} align={"center"} color={"gold.main"}>Gold</Typography></Grid>
                            <Grid item xs={3}><Typography fontWeight={600} align={"center"} color={"platinum.main"}>Platinum</Typography></Grid>
                        </Grid>
                    </Box>
                </>
            )
        }

        return (
            <Box sx={styles.detailsIndicatorWrapper}>
                {renderDetailsIndicator()}
                {details.map((detail: any, i: number) => (
                    <PlansDetailsAccordion title={detail.title} key={i} rows={detail.rows} subtext={detail.subtext} {...{t}} />
                ))}
            </Box>
        )
    }

    return (
        <>
            <div ref={plansWrapper}></div>
            {renderPlans()}
            {renderPlansDetails()}
        </>
    )
}

export default PlansTab