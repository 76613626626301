import React from "react"
import {CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function LoadingCircle(props: any) {

    const {maxHeight, message, subtitle, loadingValue} = props
    const {t} = useTranslation(["common"])

    return (
        <div style={{ height: maxHeight ? `${maxHeight}px` : "100vh", padding: "100px" }}>
            <div style={{ margin: "auto", textAlign: "center", marginTop: 200 }}>
                <CircularProgress
                    size={60}
                    variant={!!loadingValue ? "determinate" : "indeterminate"}
                    sx={{ mb: 3 }}
                    value={loadingValue}
                />
                <Typography variant={"h5"} component={"p"}>{t(message ? message : "loading")}</Typography>
                { !!subtitle && <Typography variant={"body2"}>{subtitle}</Typography> }
            </div>
        </div>
    )
}