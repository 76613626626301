import React from "react"
import {Button} from "@components";
import {CircularProgress, Grid} from "@mui/material";
import {regexEmail} from "@functions";

export default function SignupButton(props: any) {

    const {credentials, isPasswordValid, loading, handleSignupStep, verifiedRecaptcha, t} = props

    const canProceed = (
        !!credentials.username
        && regexEmail(credentials.username)
        && (!!credentials.password && isPasswordValid)
        && (!!credentials.password && (credentials.password === credentials.passwordConf))
        && credentials.attributes["custom:accepted_terms"]
    )

    const returnButtonContent = () => {
        if (!credentials.username) return t("No_email_provided")
        if (!regexEmail(credentials.username)) return t("invalid_email")
        if (!isPasswordValid) return t("Password_not_matching_requirements")
        if (!!credentials.password && (credentials.password !== credentials.passwordConf)) return t("Password_confirmation_not_matching")
        if (!credentials.attributes["custom:accepted_terms"]) return t("must_accept_terms")
        return t("Next")
    }

    return (
        <Grid item>
            <Button
                primary fullWidth
                disabled={!verifiedRecaptcha || loading || !canProceed}
                onClick={handleSignupStep}
            >
                {loading ? <CircularProgress size={21}/> : returnButtonContent()}
            </Button>
        </Grid>
    )
}