import React from "react"
import {Box, Chip, ListItemButton, ListItemText, ListSubheader, Menu} from "@mui/material";
import {DeveloperBoard, Gamepad} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {capitalizeFirstLetter} from "@functions";

const {REACT_APP_ENV} = process.env;

export default function SignupDevMenu(props: any) {

    const {credentials, setCredentials, t} = props

    const allow = !!REACT_APP_ENV && (REACT_APP_ENV === "development" || REACT_APP_ENV === "local" || REACT_APP_ENV === "sandbox")
    const history = useHistory();

    const [anchorDev, setAnchorDev] = React.useState<null | HTMLElement>(null);

    const styles = {
        wrapper: {
            position: "absolute",
            top: 10,
            left: 10
        },
        icon: { color: "#fff" },
        menu: { mt: 1 },
        menuPaper: { maxHeight: 350 },
        listSubheader: { lineHeight: 2, backgroundColor: "#00000020" },
    }

    // Functions and libs used to log or debug stuff
    const debug = () => {

        const funnels = [
            {label: "qrcodes", signupUrl: "/signup"},
            {label: "vcards", signupUrl: "/signup?product=standalone"},
            {label: "packaging", signupUrl: "/signup?product=packaging"},
            {label: "vapps", signupUrl: "/signup?product=vapps"},
            {label: "standard", signupUrl: "/signup?product=standard"},
            {label: "gold", signupUrl: "/signup?product=gold"},
            {label: "platinum", signupUrl: "/signup?product=platinum"},

        ]

        const simulateFunnel = (funnel: any) => {
            // Navigate to said funnel
            history.push(funnel.signupUrl)
        }

        const fillCredentials = () => {
            setCredentials({
                ...credentials, attributes: {
                    ...credentials.attributes,
                    name: 'Test Name',
                    family_name: 'Surname',
                    "custom:usage": 'Business',
                    "custom:company": 'Test Company',
                    "custom:company_position": t("role_1"),
                    "custom:company_industry": t("industry_1"),
                    // NEW FIELDS
                    "custom:company_service": t("service_1"),
                    "custom:qrcodes_needs": '100-500',
                    "custom:sso": true,
                }
            })
        }

        return {simulateFunnel, funnels, fillCredentials}

    }

    // Renders
    const renderMenuChip = () => {

        const handleOpenMenu = (event: any) => {
            setAnchorDev(event.currentTarget)
        }

        return (
            <>
                <Box sx={styles.wrapper}>
                    <Chip
                        icon={REACT_APP_ENV === "sandbox" ? <Gamepad sx={styles.icon} /> :  <DeveloperBoard sx={styles.icon} /> }
                        size="small"
                        label={REACT_APP_ENV === "sandbox" ? "ENV: Sandbox" : REACT_APP_ENV === "local" ? "ENV: Local" : "-"}
                        color={REACT_APP_ENV === "sandbox" ? "secondary" : REACT_APP_ENV === "local" ? "error" : "error"}
                        clickable
                        onClick={handleOpenMenu}
                    />
                </Box>
            </>
        )
    }

    const renderMenuContent = () => {
        return (
            <Menu
                id="dev-menu"
                anchorEl={anchorDev}
                keepMounted
                open={Boolean(anchorDev)}
                onClose={() => setAnchorDev(null)}
                sx={styles.menu}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
                MenuListProps={{ dense: true }}
                slotProps={{ paper: { sx: styles.menuPaper } }}
            >
                <ListSubheader sx={styles.listSubheader}>Logging</ListSubheader>
                    <ListItemButton onClick={() => console.log(credentials)}>
                        <ListItemText primary={"Log Credentials"} />
                    </ListItemButton>

                <ListSubheader sx={styles.listSubheader}>Fill debug credentials</ListSubheader>
                <ListItemButton onClick={() => debug().fillCredentials()}>
                    <ListItemText primary={"Fill credentials"} />
                </ListItemButton>

                <ListSubheader sx={styles.listSubheader}>Simulate funnels</ListSubheader>
                {debug().funnels.map((funnel: any, i: number) => (
                    <ListItemButton onClick={() => debug().simulateFunnel(funnel)} key={i}>
                        <ListItemText primary={capitalizeFirstLetter(funnel.label)} />
                    </ListItemButton>
                ))}

            </Menu>
        )
    }

    if (!allow) return <></>

    return (
        <>
            {renderMenuChip()}
            {renderMenuContent()}
        </>
    )
}