import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
// Indexed Views
import {AppList, NotFound} from "@/Views";
// Other components
import SignupComponent from "./Views/Authentication/Signup/Signup";
import {Login} from "./Views/Authentication/Login";
import ForgotPasswordComponent from "./Views/Authentication/ForgotPassword/ForgotPassword";
import VCardProApp from "./Apps/vcards/VcardPro";
import RestoAppComponent from "./Apps/RestoApp";
import WineLabelAppComponent from "./Apps/WineLabelApp";
import QRBnbAppComponent from "@/QRBnbApp";

// Suspense
import {LoadingCircle} from "@components";

export default function UnitagRoutes() {
    const Packaging = lazy(() => import('@apps/Packaging/Packaging'));
    const QRCodes = lazy(() => import('@apps/QRCodesList/QRCodesList'));
    const Analytic = lazy(() => import('./Apps/Analytics/Analytics'));
    const Settings = lazy(() => import('./Views/Account/Settings'));
    const QRCodeDetailsComponent = lazy(() => import('@apps/QRCodesDetails/QRCodeDetails'));
    const AtlasApp = lazy(() => import('./Apps/Atlas/Atlas'));
    const CampaignsDashboardComponent = lazy(() => import('./Apps/Campaigns/Campaigns'));
    const AlaCarte = lazy(() => import('./Views/Alacarte/AlaCarte'));
    const TermsAndConditions = lazy(() => import('./Views/Legal/terms-and-conditions'));
    const PrivacyPolicy = lazy(() => import('./Views/Legal/privacy'));
    const PlansComponent = lazy(() => import('./Views/Plans/Plans'));
    const BillingSuccessComponent = lazy(() => import('./Views/BillingCheckout/BillingCheckoutSuccess'));
    const NotificationsCenter = lazy(() => import("./Views/NotificationCenter/NotificationsCenterPage"));
    const Elearning = lazy(() => import("./Views/Elearning/Elearning"));
    const Dashboard = lazy(() => import("./Views/Home/HomeDashboard"))
    const WinelabelBuyCredits = lazy(() => import("./Views/Credits/Winelabel/WineLabelCredits"))
    const QRCodesGenerator = lazy(() => import('@apps/QRCodesGenerator/QRCodesGenerator'))
    const VApps = lazy(() => import('@apps/vApps/VApps'));

    return (
        <div style={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            flexGrow: 1
        }}>
            <Suspense fallback={<LoadingCircle />}>
                <Switch>

                    <Route path="/health"><h3>Healthy</h3></Route>

                    {/* Authentication */}
                    <Route exact path="/signup" component={SignupComponent}/>
                    <Route exact path="/google_sso" component={Login}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/forgot_password" component={ForgotPasswordComponent}/>

                    {/* Home */}
                    <Route exact path="/"><Dashboard /></Route>

                    {/* Credits and Modules */}
                    <Route exact path="/alacarte" component={AlaCarte}/>

                    {/* App List */}
                    <Route path="/applications" exact component={AppList}/>

                    {/* QR Codes */}
                    <Route path="/applications/qrcodes/list" component={QRCodes}/>
                    <Route path="/applications/qrcodes/edit/:id" component={QRCodeDetailsComponent}/>
                    <Route path='/applications/qrcodes/generator' component={QRCodesGenerator}/>
                    <Route path="/applications/qrcodes/generator?edit=:id" component={QRCodesGenerator}/>

                    {/* Analytics */}
                    <Route path="/applications/analytics"><Analytic/></Route>

                    {/* Atlas */}
                    <Route path="/applications/atlas"><AtlasApp/></Route>

                    {/* Vcards */}
                    <Route path="/applications/vcardpro"><VCardProApp/></Route>

                    {/* Resto */}
                    <Route path="/applications/resto/control_panel"><RestoAppComponent/></Route>

                    {/* WineLabel */}
                    <Route path="/applications/winelabel/control_panel"><WineLabelAppComponent/></Route>
                    <Route path="/applications/qrbnb/control_panel"><QRBnbAppComponent/></Route>
                    <Route path="/winelabel-buy-credits"><WinelabelBuyCredits /></Route>


                    {/* Campaigns */}
                    <Route path="/applications/campaigns"><CampaignsDashboardComponent/></Route>

                    {/* Packaging */}
                    <Route exact path="/applications/packaging" component={Packaging}/>
                    <Route path="/applications/packaging/urls"><Packaging tab="urls"/></Route>
                    <Route path="/applications/packaging/products"><Packaging tab="products"/></Route>
                    <Route path="/applications/packaging/ranges"><Packaging tab="ranges"/></Route>
                    <Route path="/applications/packaging/brands"><Packaging tab="brands"/></Route>

                    {/* vApps */}
                    <Route path={"/applications/vapps"}><VApps /></Route>

                    {/* Account & Settings */}
                    <Route path="/settings" component={Settings}/>

                    {/* Pricing */}
                    <Route path="/plans" component={PlansComponent}/>
                    <Route path="/billing/success" component={BillingSuccessComponent}/>
                    <Route path="/billing/cancel"><Redirect to="/"/></Route>

                    {/* Notifications */}
                    <Route path={"/notifications-center"} component={NotificationsCenter} />

                    {/* Elearning */}
                    <Route path={"/e-learning"} component={Elearning} />

                    {/* Legal */}
                    <Route path="/terms-and-conditions" component={TermsAndConditions}/>
                    <Route path="/privacy" component={PrivacyPolicy}/>

                    {/* Error management */}
                    <Route path="*" component={NotFound}/>
                </Switch>
            </Suspense>
        </div>
    );
}
