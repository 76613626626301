import React, {useEffect} from "react"
// MUI
import {
    Box,
    Chip,
    Collapse, IconButton, ListItem,
    ListItemButton, ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu, Paper, Stack, Tooltip,
} from "@mui/material";
import {
    AltRoute, Circle, Close,
    Code,
    Downloading,
    Grid3x3,
    LayersClear, PlayArrow,
    Textsms
} from "@mui/icons-material";
// Context
import {useUser} from "@context";
import {usePermissions} from "@/Context/User/PermissionsProvider";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import {useTour} from "@/Context/Tour/TourProvider";
import {capitalizeFirstLetter} from "@functions";
import {returnFirstVisitFunnel} from "@/functions/authenticationFunctions";
import LockIcon from "@mui/icons-material/Lock";
import localstorageItems from "@/constants/localstorageItems";

export default function DevMenu(props: any) {

    const { REACT_APP_ENV } = props

    const ENABLED = REACT_APP_ENV === "development" || REACT_APP_ENV === "local" || REACT_APP_ENV === "sandbox";

    const {
        getUser, user,
        getComplexUser, complexUser,
        getCredits, credits,
        getCreditsDetails, creditsDetails,
        getBillingMethod, billingMethod,
        getBillingInvoice, billingInvoice,
        getOrganisation, organisation,
        organisationInvitations,
        getOrganisationAsMember, organisationAsMember
    } = useUser()

    const {
        accessPermissions, usagePermissions
    } = usePermissions()

    const {isFirstVisit, setIsFirstVisit, firstVisitFunnel, setFirstVisitFunnel} = useTour()

    const styles = {
        chip: { mr: 2 },
        menu: { mt: 1 },
        menuPaper: { maxHeight: 350 },
        divider: { my: 1 },
        listSubheader: { lineHeight: 2, backgroundColor: "#d8d8d8" },
        nestedList: { "& div": { py: 0 }, "& span": { pl: 2 } },

        devToolbar: {
            wrapper: {
                position: "fixed",
                right: 24,
                top: 66,
            },
            paper: {
                p: "3px",
                backgroundColor: ENABLED ? "secondary.main" : "grey",
                borderRadius: "0 0 6px 6px",
            }
        },
        funnelChip: {
            backgroundColor: "success.main",
            color: "white",
            mb: "-4px",
            mr: 1,
            cursor: "default"
        }
    }

    const [anchorLogger, setAnchorLogger] = React.useState<null | HTMLElement>(null);
    const [anchorPermissions, setAnchorPermissions] = React.useState<null | HTMLElement>(null);
    // New dev menu
    const [openDev, setOpenDev] = React.useState<boolean>(false)
    // // Dev sub-menus anchors
    const [funnelAnchor, setFunnelAnchor] = React.useState<null | HTMLElement>(null);

    let funnelLocalStorage = window.localStorage.getItem(localstorageItems.signupFunnel);

    useEffect(() => {
        if (ENABLED) {
            setOpenDev(true)
        }
    }, [ENABLED]);

    if (!ENABLED) { return null }

    // Control
    const displayHydrationError = () => {
        console.log("%cError: " +
            "This data set is not hydrated yet. " +
            "Refresh it manually or try this command on a page that requires this data's hydration.",
            "color: red")
    }

    // Hydration
    const fetchAll = () => {
        getUser()
        getComplexUser()
        getCredits()
        getCreditsDetails()
        getBillingInvoice()
        getBillingMethod()
        getOrganisation()
        getOrganisationAsMember()
    }

    // NEW DEV MENU
    const renderUuidLogger = () => {

        const copyUuid = () => {
            if (!!complexUser.uuid) navigator.clipboard.writeText(complexUser.uuid).then()
        }

        return (
            <>
                <Tooltip title={"Copy UUID"} placement={"bottom"} arrow>
                   <div>
                       <IconButton size={"small"} onClick={() => copyUuid()} disabled={!complexUser?.uuid}>
                           <Grid3x3 sx={{ color: !complexUser?.uuid ? "grey" : "white" }} fontSize={"inherit"} />
                       </IconButton>
                   </div>
                </Tooltip>
            </>
        )
    }

    const renderOrgLogger = () => {

        const copyUuid = () => {
            if (!!complexUser.org) navigator.clipboard.writeText(complexUser.org).then()
        }

        return (
            <Tooltip title={"Copy Org UUID"} placement={"bottom"} arrow>
                <div>
                    <IconButton size={"small"} onClick={() => copyUuid()} disabled={!complexUser?.org}>
                        <GroupWorkIcon sx={{ color: !complexUser?.org ? "lightgrey" : "white" }} fontSize={"inherit"} />
                    </IconButton>
                </div>
            </Tooltip>
        )
    }

    const renderUserLogger = () => {

        const handleLog = (object: any) => {
            if (!!object) console.log(object)
            else displayHydrationError()
            setAnchorLogger(null)
        }

        const loggers = [
            { label: "User (simple)", object: user },
            { label: "User (complex)", object: complexUser },
            { label: "Credits", object: credits },
            { label: "Credits Details", object: creditsDetails },
            { label: "Billing Method", object: billingMethod },
            { label: "Last invoice", object: billingInvoice },
            { label: "Organisation", object: organisation },
            { label: "Org-As-Member", object: organisationAsMember },
            { label: "Org-Invitations", object: organisationInvitations },
        ]

        return (
            <>
                <Tooltip title={"Logger"} placement={"bottom"} arrow>
                    <IconButton size={"small"} onClick={(e: any) => setAnchorLogger(e.currentTarget)}>
                        <Textsms sx={{ color: "white" }} fontSize={"inherit"} />
                    </IconButton>
                </Tooltip>

                <Menu
                    id="dev-menu"
                    anchorEl={anchorLogger}
                    keepMounted
                    open={Boolean(anchorLogger)}
                    onClose={() => setAnchorLogger(null)}
                    sx={styles.menu}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    transformOrigin={{vertical: "top", horizontal: "center"}}
                    MenuListProps={{ dense: true }}
                    slotProps={{ paper: { sx: styles.menuPaper } }}
                >
                    <ListSubheader sx={styles.listSubheader}>API data</ListSubheader>
                    {
                        loggers.map((logger: any, i: number) => (
                            <ListItemButton onClick={() => handleLog(logger.object)} key={i}>
                                <ListItemText primary={logger.label} />
                            </ListItemButton>
                        ))
                    }
                </Menu>
            </>
        )
    }

    const renderGlobalFetcher = () => {

        return (
            <Tooltip title={"Fetch all"} placement={"bottom"} arrow>
                <IconButton size={"small"} onClick={() => fetchAll()}>
                    <Downloading sx={{ color: "white" }} fontSize={"inherit"} />
                </IconButton>
            </Tooltip>
        )
    }

    const renderFunneler = () => {

        const funnels = [
            { label: "default-reco-plat", attributes: { "custom:sso": false, "custom:qrcodes_needs": "500+" }, product: false },
            { label: "standalone-reco-plat", attributes: { "custom:sso": false, "custom:qrcodes_needs": "" }, product: "standalone" },
            { label: "default-reco-std/gld", attributes: { "custom:sso": false, "custom:qrcodes_needs": "1-100" }, product: false },
            { label: "default-reco-plat-sso", attributes: { "custom:sso": true, "custom:qrcodes_needs": "500+" }, product: false },
            { label: "packaging-reco-plat-sso", attributes: { "custom:sso": true, "custom:qrcodes_needs": "500+" }, product: "packaging" }
        ]

        const setFunnel = (funnel: any) => {
            setFirstVisitFunnel(returnFirstVisitFunnel(funnel.attributes, funnel.product))
            setIsFirstVisit(true)
            setFunnelAnchor(null)
        }

        const clearFunnel = () => {
            setFirstVisitFunnel(null)
            setIsFirstVisit(false)
            setFunnelAnchor(null)
        }

        const setLocalStorageFunnel = (funnel: any) => {
            window.localStorage.setItem(localstorageItems.signupFunnel, returnFirstVisitFunnel(funnel.attributes, funnel.product))
        }

        const renderFunnelerMenu = () => {
            return (
                <Menu
                    id="funnel-menu"
                    anchorEl={funnelAnchor}
                    keepMounted
                    open={Boolean(funnelAnchor)}
                    onClose={() => setFunnelAnchor(null)}
                    sx={styles.menu}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    transformOrigin={{vertical: "top", horizontal: "center"}}
                    MenuListProps={{ dense: true }}
                    slotProps={{ paper: { sx: styles.menuPaper } }}
                >
                    <ListItemButton onClick={() => {
                        setIsFirstVisit(!isFirstVisit)
                        setFunnelAnchor(null)
                    }}>
                        <ListItemIcon sx={{ minWidth: 24 }}><PlayArrow sx={{ fontSize: 18 }}  /></ListItemIcon>
                        <ListItemText primary={"Simulate First Visit"} />
                    </ListItemButton>
                    <ListItemButton onClick={() => clearFunnel()}>
                        <ListItemIcon sx={{ minWidth: 24 }}><Close sx={{ fontSize: 18 }} /></ListItemIcon>
                        <ListItemText primary={"Clear Funnels"} />
                    </ListItemButton>
                    <ListSubheader sx={styles.listSubheader}>Set Local Storage Funnels</ListSubheader>
                    <ListItem>
                        <ListItemIcon sx={{ minWidth: 24 }}>
                            <Circle sx={{ fontSize: 18 }} color={funnelLocalStorage ? "primary" : "disabled"} />
                        </ListItemIcon>
                        <ListItemText secondary={"Saved funnel"} />
                    </ListItem>
                    {
                        funnels.map((funnel, i) => (
                            <ListItemButton onClick={() => setLocalStorageFunnel(funnel)} key={i}>
                                <ListItemText primary={capitalizeFirstLetter(funnel.label)} />
                            </ListItemButton>
                        ))
                    }

                    <ListSubheader sx={styles.listSubheader}>Set Specific Funnels</ListSubheader>
                    {
                        funnels.map((funnel, i) => (
                            <ListItemButton key={i} onClick={() => setFunnel(funnel)}>
                                <ListItemText primary={capitalizeFirstLetter(funnel.label)} />
                            </ListItemButton>
                        ))
                    }
                </Menu>
            )
        }

        return (
            <>
                <Tooltip title={"Funneling menu"} placement={"bottom"} arrow>
                    <IconButton
                        size={"small"}
                        onClick={(e) => setFunnelAnchor(e.currentTarget)}
                        sx={{ backgroundColor: isFirstVisit ? "success.main" : "transparent" }}
                    >
                        <AltRoute sx={{ color: "white" }} fontSize={"inherit"} />
                    </IconButton>
                </Tooltip>

                {renderFunnelerMenu()}
            </>
        )
    }

    const renderLocalClear = () => {

        const clearLocalStorage = () => {
            window.localStorage.removeItem(localstorageItems.signupFunnel)
        }

        return (
            <Tooltip title={"Clear Local Storage"} placement={"bottom"} arrow>
                <IconButton size={"small"} onClick={() => clearLocalStorage()}>
                    <LayersClear sx={{ color: "white" }} fontSize={"inherit"} />
                </IconButton>
            </Tooltip>
        )
    }

    const renderPermissionsLogger = () => {
        const handleLog = (object: any) => {
            if (!!object) console.log(object)
            else displayHydrationError()
            setAnchorLogger(null)
        }

        const permissions = [
            { label: "Access Permissions", object: accessPermissions },
            { label: "Usage Permissions", object: usagePermissions },
        ]

        return (
            <>
                <Tooltip title={"Permissions"} placement={"bottom"} arrow>
                    <IconButton size={"small"} onClick={(e: any) => setAnchorPermissions(e.currentTarget)}>
                        <LockIcon sx={{ color: "white" }} fontSize={"inherit"} />
                    </IconButton>
                </Tooltip>

                <Menu
                    id="perm-menu"
                    anchorEl={anchorPermissions}
                    keepMounted
                    open={Boolean(anchorPermissions)}
                    onClose={() => setAnchorPermissions(null)}
                    sx={styles.menu}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    transformOrigin={{vertical: "top", horizontal: "center"}}
                    MenuListProps={{ dense: true }}
                    slotProps={{ paper: { sx: styles.menuPaper } }}
                >
                    {
                        permissions.map((logger: any, i: number) => (
                            <ListItemButton onClick={() => handleLog(logger.object)} key={i}>
                                <ListItemText primary={logger.label} />
                            </ListItemButton>
                        ))
                    }
                </Menu>
            </>
        )
    }

    const renderFunnel = () => {
        return (
            <>
                <Collapse orientation={"horizontal"} in={!!firstVisitFunnel && !!isFirstVisit}>
                    <Tooltip title={"Currently selected funnel"} placement={"bottom"} arrow>
                        <Chip
                            sx={styles.funnelChip}
                            icon={<AltRoute sx={{ color: "white!important" }} />}
                            label={String(firstVisitFunnel)}
                            size="small"
                        />
                    </Tooltip>
                </Collapse>
                <Collapse orientation={"horizontal"} in={!!funnelLocalStorage}>
                    <Tooltip title={"Storaged funnel"} placement={"bottom"} arrow>
                        <Chip
                            sx={styles.funnelChip}
                            icon={<AltRoute sx={{ color: "white!important" }} />}
                            label={String(funnelLocalStorage)}
                            size="small"
                        />
                    </Tooltip>
                </Collapse>
            </>
        )
    }

    return (
        <>
            {
                ENABLED &&
                    <>
                        <Chip
                            sx={styles.chip}
                            icon={<Code sx={{ color: "#ffffff"}} />}
                            size="small"
                            label={REACT_APP_ENV === "sandbox" ? "SANDBOX" : REACT_APP_ENV === "local" ? "LOCAL" : "ERROR - ENVIRONMENT NOT SET"}
                            color={"secondary"}
                            clickable
                            // onClick={handleOpenMenu}
                            onClick={() => setOpenDev(!openDev)}
                        />

                        <Box sx={styles.devToolbar.wrapper}>
                            <Collapse in={openDev}>
                                <Paper variant={"outlined"} sx={styles.devToolbar.paper}>
                                    <Stack direction={"row"}>
                                        {renderFunnel()}
                                        {renderUserLogger()}
                                        {renderPermissionsLogger()}
                                        {renderGlobalFetcher()}
                                        {renderLocalClear()}
                                        {renderFunneler()}
                                        {renderOrgLogger()}
                                        {renderUuidLogger()}
                                    </Stack>
                                </Paper>
                            </Collapse>
                        </Box>
                    </>

            }


        </>
    )
}