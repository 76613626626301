import React from "react"
import {Alert, Grid, Paper, Stack, Typography} from "@mui/material";
import ReactCodeInput from "react-code-input";
import {useAuthentication} from "@/Context/Authentication/AuthenticationProvider";

// TODO: there's a slight delay when entering the code during which the UI is still available - add loading suspense
export default function TwoFactorAuthentication(props: any) {

    const {t} = props
    const {check2FAChange, is2FACodeChecked, is2FACodeValid} = useAuthentication()

    const styles = {
        codePaper: {p: 2, my: 2},
        enterCode: {fontWeight: 700, color: '#4f566b', mt:1}
    }

    return (
        <>
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                        {t("Login_2fa_title")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack justifyContent="center" alignItems="center">
                        <Typography variant='body1' align={"center"}>
                            {t("Login_2fa_intro")}
                        </Typography>
                        <Paper variant={'outlined'} sx={styles.codePaper}>
                            <ReactCodeInput
                                type='text'
                                fields={6}
                                onChange={(e) => check2FAChange(e)}
                                name="verificationCodeInput"
                                inputMode={'latin'}
                            />
                            <Typography variant={'body1'} sx={styles.enterCode} align={"center"}>
                                {t("Login_2fa_enter_code")}
                            </Typography>
                        </Paper>
                        {(is2FACodeChecked && !is2FACodeValid) && <Alert severity={'error'}>{t("Login_2fa_error")}</Alert>}
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}