
const companyIndustries = [
    "industry_1", "industry_2", "industry_3", "industry_4", "industry_5", "industry_6", "industry_7",
    "industry_8", "industry_9", "industry_10", "industry_11", "industry_12", "industry_13", "industry_14",
    "industry_15", "industry_16", "industry_17", "industry_18", "industry_19", "industry_20", "industry_21",
    "industry_22", "industry_23", "industry_24", "industry_25", "industry_26", "industry_27", "industry_28",
    "industry_29", "industry_30", "industry_31",
]

const companyServices = [
    "service_1", "service_2", "service_3", "service_4", "service_5", "service_6", "service_7",
    "service_8", "service_9", "service_10", "service_11", "service_12", "service_13", "service_14",
    "service_15", "service_16", "service_17", "service_18", "service_19", "service_20", "service_21",
    "service_22", "service_23", "service_24", "service_25", "service_26", "service_27"
]

const companyRoles = [
    "role_1", "role_2", "role_3", "role_4", "role_5", "role_6"
]

export { companyIndustries, companyServices, companyRoles }