import {useEffect, useState} from "react";
import {
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";

// Used to redirect users to proper SSO portal when entering a matching email addr. on /SIGNUP page
// @params: email<any>
function checkSsoSignupToLogin(email: any) {
    switch(true) {
        case email.includes("bouygues-construction"):
        case email.includes("losinger-marazzi.ch"):
            window.location.replace('https://bouygues.console.unitag.io'); break;
        case email.includes("@sisley"):
            window.location.replace('https://sisley.console.unitag.io'); break;
        case email.includes("@equans"):
            window.location.replace('https://equans.console.unitag.io'); break;
        case email.includes("@adeo"):
            window.location.replace('https://adeo.console.unitag.io'); break;
        case email.includes("@bonduelle"):
            window.location.replace('https://bonduelle.console.unitag.io'); break;
        case email.includes("@maison-colibri.com"):
        case email.includes("@nuwen.com"):
        case email.includes("@patisseriesgourmandes.com"):
        case email.includes("@phosphea.com"):
        case email.includes("@roullier.com"):
        case email.includes("@timacagro.com"):
            window.location.replace('https://roullier.console.unitag.io'); break;
        case email.includes("@groupeseb"):
            window.location.replace('https://seb.console.unitag.io'); break;
        default: return;
    }


    // LEGACY VERSION - RESTORE IF THINGS TURN TO SHIT
    // if (email.includes('bouygues-construction') || email.includes("losinger-marazzi.ch")) {
    //     window.location.replace('https://bouygues.console.unitag.io')
    //     return
    // } else if (email.includes('@sisley')) {
    //     window.location.replace('https://sisley.console.unitag.io')
    //     return
    // } else if (email.includes('@adeo')) {
    //     window.location.replace('https://adeo.console.unitag.io')
    //     return
    // } else if (email.includes('@equans')) {
    //     window.location.replace('https://equans.console.unitag.io')
    //     return
    // } else if (email.includes('@bonduelle')) {
    //     window.location.replace('https://bonduelle.console.unitag.io')
    //     return
    // } else if (
    //     email.includes('@maison-colibri.com') ||
    //     email.includes('@nuwen.com') ||
    //     email.includes('@patisseriesgourmandes.com') ||
    //     email.includes('@phosphea.com') ||
    //     email.includes('@roullier.com') ||
    //     email.includes('@timacagro.com')
    // ) {
    //     window.location.replace('https://roullier.console.unitag.io')
    //     return
    // } else if (email.includes('@groupeseb')) {
    //     window.location.replace('https://seb.console.unitag.io')
    //     return
    // }
}

// Used to redirect new or existing user to specific onboarding funnels
// @params: product<string | null>, history<useHistory>, vcardsNumber?<number>
const redirectByPath = (product: string | null, history: any, vcardsNumber?: number) => {
    switch (product) {
        case "vcards":
            history.push("/applications/vcard/control_panel");
            break;
        case "standalone":
        case "buy-vcards":
            history.push({ pathname: "/alacarte", state: { fromPlan: standaloneSubscription, topUp: true, quantity: vcardsNumber } });
            break;
        case "plans":
            history.push("/plans");
            break;
        case "winelabel":
            history.push("/winelabel-buy-credits");
            break;
        case "standard":
        case "standard-plan":
            history.push({ pathname: '/alacarte', state: {fromPlan: standardSubscription} });
            break;
        case "gold":
        case "gold-plan":
            history.push({ pathname: '/alacarte', state: {fromPlan: goldSubscription} });
            break;
        case "platinum":
        case "platinum-plan":
            history.push({ pathname: '/alacarte', state: {fromPlan: platinumSubscription} });
            break;
        case '':
        default:
            history.push("/");
            break;
    }
}

// FUNNELS
/**
* @see `firstVisitFunnel` in `TourProvider.tsx`
* @returns {string}
* "[product]-reco-[plan/upsale][-sso]"
**/
const returnFirstVisitFunnel = (attributes: any, product: any): string => {

    if (!attributes) return "qrcodes"

    let sso = attributes["custom:sso"]
    let needs = attributes["custom:qrcodes_needs"]
    let funnel: string

    switch (product) {
        case "vcards":
            // funnel = "vcards"; break;
            return ""
        case "standalone":
        case "buy-vcards":
            // funnel = "standalone"; break;
            return ""
        case "plans":
            funnel = "plans"; break;
        case "standard":
        case "standard-plan":
            funnel = "standard"; break;
        case "gold":
        case "gold-plan":
            funnel = "gold"; break;
        case "platinum":
        case "platinum-plan":
            funnel = "platinum"; break;
        case "packaging":
            funnel = "packaging"; break;
        case '':
        default:
            funnel = "default"; break;
    }

    switch (needs) {
        case "1-100":
            funnel = funnel + "-reco-std/gld"; break;
        case "100-500":
            funnel = funnel + "-reco-gld/plat"; break;
        case "500+":
            funnel = funnel + "-reco-plat"; break;
        case "":
        default:
            funnel = funnel + "-reco-gld/plat"; break;
    }

    if (sso) funnel = funnel + "-sso"

    return funnel
}

// Hook checking for password validity (does not store any password)
function usePasswordRules() {

    const passwordRulesInit = {
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        characters: false,
    }

    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [passwordRules, setPasswordRules] = useState<any>(passwordRulesInit);

    useEffect(() => {
        setIsPasswordValid(Object.values(passwordRules).every(Boolean))
    }, [passwordRules]);

    const checkPassword = (password: string) => {
        if (password === "") setPasswordRules(passwordRulesInit)
        else {
            let pwd = password;
            let format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
            setPasswordRules({
                length: pwd.length >= 8,
                uppercase: pwd.toLowerCase() !== pwd,
                lowercase: pwd.toUpperCase() !== pwd,
                number: /\d/.test(pwd),
                characters: format.test(pwd)
            })
        }
    }

    return { isPasswordValid, checkPassword, passwordRules }
}

export { checkSsoSignupToLogin, usePasswordRules, redirectByPath, returnFirstVisitFunnel }