import React, {useState, useEffect} from "react"
import {Link as RouterLink, useHistory, useLocation} from "react-router-dom";
// hooks & translation
import {useTranslation} from "react-i18next";
// MUI
import {
    CircularProgress,
    FormControl,
    Grid,
    InputAdornment,
    Link,
    TextField,
} from "@mui/material";
import {AccountCircle, LoginRounded} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
// Custom components
import {Button} from "@components";
import {useAuthentication} from "@/Context/Authentication/AuthenticationProvider";
import {TwoFactorAuthentication} from "@/Views/Authentication/Login/components";
import AuthenticationWrapper from "@/Views/Authentication/components/AuthenticationWrapper";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Login() {
    const {
        login,
        loginCredentials, setLoginCredentials,
        handleOpen2FA, client2FA,
        // SSO
        sso, loginWithSso,
        redirectSsoFromLogin,
        setSsoDataFromPath,
    } = useAuthentication()

    const {t} = useTranslation(['common']);
    let query = useQuery();
    const history = useHistory();
    const {checkLoggedInUser} = useAuthentication()

    // loading
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = React.useState<null | string>("");

    useEffect(() => {
        // Stop processes if click-jacking
        if (window.self !== window.top) return

        let path = window.location.href
        setSsoDataFromPath(path)

        const eid = query.get("eid");
        if (eid !== undefined && eid !== null) history.push("/signup");

        const followedProduct = query.get("product");
        setProduct(followedProduct);

        // This async call does not need to be caught for it manages redirection and will always throw an error if no credentials
        checkLoggedInUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Login
    const handleLogin = (e: any) => {
        setLoading(true)
        login(e, loginCredentials).then((res: any) => {
            if (!!res) {
               if (res === "NO_LOGIN"){
                   // Mail/PWD error
                   setLoading(false)
               } else {
                   // 2FA triggered - set state with temporary client
                   handleOpen2FA(res)
                   setLoading(false)
               }
            }
        })
    }

    // SSO and logos
    const returnLogoSrc = () => {
        if (!!sso && !!sso.logo) return `/modules/${sso.logo}`
        return "/assets/logo-unitag.svg"
    }

    // email handler
    const emailOnChange = (e: any) => {
        setLoginCredentials({...loginCredentials, username: e.target.value})
        redirectSsoFromLogin(e.target.value)
    }

    // Renders
    const renderLoginForm = () => {
        if (!!sso && !!sso.provider) return <></>
        return (
            <>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        label={t("Mail")}
                        id="email"
                        placeholder={t("Your_mail")}
                        onChange={emailOnChange}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>

                <br/>
                <br/>

                <FormControl fullWidth variant="outlined">
                    <TextField
                        label={t("Password")}
                        type="password"
                        id="password"
                        onChange={(e) => loginCredentials.password = e.target.value}
                        placeholder={t("Your_password")}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </>
        )
    }

    const renderRedirections = () => {
        if (!!sso && !!sso.provider) return <></>
        return (
            <>
                <Grid container sx={{ my: 2 }} justifyContent={{ xs: "center", md: "space-between" }}>
                    <Grid item>
                        <Link
                            component={RouterLink}
                            to={`/signup${product ? "?product=" + product : ""}`}
                            color="secondary"
                        >
                            {t("Signup")}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link component={RouterLink} to="/forgot_password" color="secondary">
                            {t("Forgot_password")}
                        </Link>
                    </Grid>
                </Grid>
            </>
        )
    }

    const renderActions = () => {
        if (!!sso && !!sso.provider) {
            // WATCH OUT this button should NOT have the type=submit for it triggers a different login function
            return (
                <Button
                    primary
                    large
                    fullWidth
                    startIcon={<LoginRounded />}
                    onClick={()=> loginWithSso()}
                >
                    {t("Login")}
                </Button>
            )
        }

        return (
            <Button primary fullWidth type="submit" disabled={loading}>
                {loading ? <CircularProgress size={21} sx={{ m: 1 }}/> : t("Login")}
            </Button>
        )
    }

    // Decide whether to use login form or 2FA form
    const renderProperLoginMethod = () => {

        if (!!client2FA) return <TwoFactorAuthentication {...{t}} />

        return (
            <form onSubmit={(e) => handleLogin(e)}>
                {renderLoginForm()}
                {renderRedirections()}
                {renderActions()}
            </form>
        )
    }

    return (
        <AuthenticationWrapper login logoSrc={returnLogoSrc()} {...{t}}>
            {renderProperLoginMethod()}
        </AuthenticationWrapper>
    )
}
