import React from "react"
// MUI
import {Button, Collapse, Divider, Grid, Paper, Typography} from "@mui/material";
import {Help, QrCode2Outlined, Storage} from "@mui/icons-material";
// Components
import {ModuleBilling} from "@/Views/Account/components/BillingAndPlans/perService/ModuleBilling";

export default function ServiceBillingList(props: any) {

    const {
        creditsQRCodes, creditsStorage,
        currency, subscriptionInterval,
        handleOpenModuleUnsubscribeDialog,
        services, tailoredApps,
        styles, t
    } = props

    const [openBillingHelper, setOpenBillingHelper] = React.useState(false);

    const _styles = {
        iconList: {
            height: '25px',
            fontSize: 25,
            fill: '#525252',
        }
    }

    // renders
    const renderPlanServicesSection = () => {

        const defaultServices = [
            {
                type: 'qrcodes',
                price: 0,
                price_per_unit: 0,
                credit: {...creditsQRCodes},
                icon: <QrCode2Outlined style={_styles.iconList}/>
            },
            {
                type: 'storage',
                price: 0,
                price_per_unit: 0,
                credit: {...creditsStorage},
                icon: <Storage style={_styles.iconList}/>
            }
        ]

        return (
            <>
                <Grid item xs={12}>
                    <Divider textAlign={'left'}>
                        <Typography variant={"h6"} component={"p"}>
                            {t('plan_services')}
                        </Typography>
                    </Divider>
                    <Typography variant="body2" style={styles.subtitle}>{t("plan_services_intro")}</Typography>
                </Grid>

                {
                    defaultServices.map((ds: any, i: number) => (
                        <Grid item xs={12} md={6} key={i}>
                            <ModuleBilling
                                {...{t}}
                                service={ds}
                                included
                            />
                        </Grid>
                    ))
                }
            </>
        )
    }

    const renderTailoredAppsSection = () => {

        if (!tailoredApps || !tailoredApps.length) return <></>

        return (
            <>
                <Grid item xs={12}>
                    <Divider textAlign={'left'}>
                        <Typography variant={"h6"} component={"p"}>
                            {t('tailored_apps')}
                        </Typography>
                    </Divider>
                    <Typography variant="body2" style={styles.subtitle}>{t("tailored_apps_intro")}</Typography>
                </Grid>

                {
                    !!tailoredApps && tailoredApps.map((service: any, i: number) => (
                        <Grid item xs={12} md={6} key={`tailored-${i}`}>
                            <ModuleBilling
                                {...{t}}
                                service={service}
                                currency={currency}
                                interval={subscriptionInterval}
                                tailored
                            />
                        </Grid>
                    ))
                }
            </>
        )
    }

    const renderExtraModulesSection = () => {

        if (!services || !services.length) return <></>

        return (
            <>
                <Grid item xs={12}>
                    <Divider textAlign={'left'}>
                        <Typography variant={"h6"} component={"p"}>
                            {t('extra_modules')}
                        </Typography>
                    </Divider>
                    <Typography variant="body2" style={styles.subtitle}>{t("extra_modules_intro")}</Typography>
                </Grid>

                {
                    !!services && services.map((service: any, i: number) => (
                        <Grid item xs={12} md={6} key={`module-${i}`}>
                            <ModuleBilling
                                {...{handleOpenModuleUnsubscribeDialog, t}}
                                service={service}
                                currency={currency}
                                interval={subscriptionInterval}
                            />
                        </Grid>
                    ))
                }
            </>
        )
    }

    const renderBottomHelper = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Button
                        size={"small"}
                        variant={"text"}
                        color={"secondary"}
                        startIcon={<Help />}
                        onClick={() => setOpenBillingHelper(!openBillingHelper)}
                    >
                        {t("incomplete_billing")}
                    </Button>
                    <Collapse in={openBillingHelper} sx={{ mt: 2 }}>
                        <Paper variant={"outlined"} sx={{ p: 2 }}>
                            <Typography variant={"body2"}>
                                {t("incomplete_billing_intro")}
                            </Typography>
                        </Paper>
                    </Collapse>
                </Grid>
            </>
        )
    }

    return (
        <>
            {renderPlanServicesSection()}
            {renderTailoredAppsSection()}
            {renderExtraModulesSection()}
            {renderBottomHelper()}
        </>
    )
}