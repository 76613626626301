import React, {createContext, useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {useUser} from "@context";
import {APIGet, APIPut} from "@api";
import {useSnackbar} from "@hooks";
import {TourObject, TourServiceName} from "@/interfaces/variousInterfaces";
import TourLib from "@/Context/Tour/TourLib";
import {FirstVisitDialog} from "@components";
import {useAuthentication} from "@/Context/Authentication/AuthenticationProvider";
import {usePermissions} from "@/Context/User/PermissionsProvider";
import {freeSubscription} from "@/constants/plans_en";
import localstorageItems from "@/constants/localstorageItems";

const {REACT_APP_API_URL} = process.env;

const TourContext = createContext<any>(null)

export const useTour = () => useContext(TourContext)

// How it works?
// Each tour has a named state (which represents whether it is displayed or not and its step)
// // and a [tour]steps object containing the tour's contents
export function TourProvider({children}: any) {

    const {t} = useTranslation("tours")
    const {user} = useUser()
    const {signupFunnel} = useAuthentication()
    const {handleError} = useSnackbar()
    const {trueAccountType} = usePermissions()
    // Tour steps import
    const {homeTourSteps} = TourLib()

    // FIRST VISIT
    const [isFirstVisit, setIsFirstVisit] = useState<boolean>(false)
    const [firstVisitFunnel, setFirstVisitFunnel] = useState<string | null>(null)
    // GENERAL SETTINGS
    const [userTours, setUserTours] = useState<null | TourObject[]>(null)

    // Nasty first visit tutorial detection
    let showTutorial = isFirstVisit && !firstVisitFunnel

    useEffect(() => {
        // We make sure that user has preloaded in order to fetch tours
        if (!!user) getUserTours()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        setFirstVisitFunnel(signupFunnel)
    }, [signupFunnel]);

    useEffect(() => {
        if (!!user && !!trueAccountType) {
            if (trueAccountType === freeSubscription) {
                let leftovers = window.localStorage.getItem(localstorageItems.signupFunnel)
                if (!!leftovers) setFirstVisitFunnel(leftovers)
            } else window.localStorage.removeItem(localstorageItems.signupFunnel)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trueAccountType]);

    const getUserTours = () => {
        // We get tours response from the API
        APIGet(REACT_APP_API_URL + "/user/setting").then((res: any) => {
            setUserTours(res.parsedBody.tours)
        }).catch(() => {
            handleError(t("error_getting_tour_progression"))
        })
    }

    // HOME TOUR
    const [homeTour, setHomeTour] = useState<number | undefined>(undefined)


    // DISPATCH TOUR STATES
    useEffect(() => {
        if (!!userTours && userTours.length > 0) {
            // We individually check for each tour available and change states accordingly
            // The approach may seem counterintuitive, but we specifically carve for the `false` statement when values
            // are not undefined only.
            // Take that, optimisation!
            let firstVisit = userTours.find((tour: any) => tour.service_name === "first-visit")
            if (firstVisit !== undefined && firstVisit.done === false) setIsFirstVisit(true)
        }
    }, [userTours]);

    const handleTourEnd = (tour: TourServiceName) => {

        if (tour === "first-visit") setIsFirstVisit(false)

        let body: TourObject = {
            service_name: tour,
            done: true
        }

        APIPut(REACT_APP_API_URL + "/user/tour", body).then().catch(() => {
            handleError(t("error_saving_tour_progression"))
        })
    }

    return (
        <TourContext.Provider value={{
            useTour,
            handleTourEnd,
            // First visit
            isFirstVisit, setIsFirstVisit,
            firstVisitFunnel, setFirstVisitFunnel,
            showTutorial,
            // Home Dashboard
            homeTour, setHomeTour, homeTourSteps
        }}>
            {children}
            <FirstVisitDialog />
        </TourContext.Provider>
    )
}