
interface ssoProvider {
    provider: string
    initialPath: string[]
    redirectionPath: string
    emailDomain: string[]
    logo?: string
    disableRedirection?: boolean
}

const ssoProviders: ssoProvider[] = [
    {
        provider: "Bouygues",
        initialPath: ["bouygues", "losinger-marazzi"],
        redirectionPath: "https://bouygues.console.unitag.io",
        emailDomain: ["bouygues-construction", "losinger-marazzi.ch"],
        logo: "bouygues.png"
    },
    {
        provider: "Sisley",
        initialPath: ["sisley"],
        redirectionPath: "https://sisley.console.unitag.io",
        emailDomain: ["@sisley"],
        logo: "sisley.png"
    },
    {
        provider: "bonduelle",
        initialPath: ["bonduelle"],
        redirectionPath: "https://bonduelle.console.unitag.io",
        emailDomain: ["@bonduelle"],
        logo: "bonduelle.png"
    },
    {
        provider: "Adeo",
        initialPath: ["adeo"],
        redirectionPath: "https://adeo.console.unitag.io",
        emailDomain: ["@adeo"],
        logo: "Adeo.png"
    },
    {
        provider: "Equans",
        initialPath: ["equans"],
        redirectionPath: "https://equans.console.unitag.io",
        emailDomain: ["@equans"],
        logo: "equans.jpg"
    },
    {
        provider: "Roullier",
        initialPath: ["roullier"],
        redirectionPath: "https://roullier.console.unitag.io",
        emailDomain: ["@maison-colibri.com", "@nuwen.com", "@patisseriesgourmandes.com", "@phosphea.com", "@roullier.com", "@timacagro.com"],
        logo: "roullier.jpg"
    },
    {
        provider: "SEB",
        initialPath: ["seb"],
        redirectionPath: "https://seb.console.unitag.io",
        emailDomain: ["@groupeseb"],
        logo: "groupe-seb.png"
    }
]

export default ssoProviders;