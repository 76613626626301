import React from "react"
import {
    Button, Checkbox, CircularProgress,
    Collapse,
    FormControl, FormControlLabel,
    Grid,
    InputLabel, MenuItem,
    Paper,
    Select,
    TextField,
} from "@mui/material";
import {companyIndustries, companyRoles, companyServices} from "@/constants/signupCompanyDetails";

export default function SignupFormUserProfile(props: any) {

    const {setSignupStep, credentials, setCredentials, signup, loading, product, t} = props

    const handleCredentialAttributesChange = (attr: string, value: any) => {
        setCredentials({
            ...credentials,
            attributes: {...credentials.attributes, [attr]: value }
        })
    }

    const handleBack = () => {
        setCredentials({...credentials, password: "", passwordConf: ""})
        setSignupStep("")
    }

    // Form render
    // // Legacy function - mandatory info for legacy individual users
    const renderMandatoryForm = () => {

        const renderProperQuantityForm = () => {
            if (!!product && (product === "vcards" || product === "standalone")) return <></>
            if (!!product && product === "packaging") {
                return (
                    <FormControl required fullWidth size={"small"}>
                        <InputLabel id={"skuNeeds"}>{t("sku_needs")}</InputLabel>
                        <Select
                            required
                            label={t("sku_needs")}
                            labelId={"skuNeeds"}
                            value={credentials.attributes["custom:sku_needs"]}
                            onChange={(e) => handleCredentialAttributesChange("custom:sku_needs", e.target.value)}
                        >
                            {
                                ["1-10", "11-100", "101-500", "500+"].map((need: string, i: number) => (
                                    <MenuItem key={i} value={need}>{need}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                )
            } else {
                return (
                    <FormControl required fullWidth size={"small"}>
                        <InputLabel id={"qrNeeds"}>{t("qrcodes_needs")}</InputLabel>
                        <Select
                            required
                            label={t("qrcodes_needs")}
                            labelId={"qrNeeds"}
                            value={credentials.attributes["custom:qrcodes_needs"]}
                            onChange={(e) => handleCredentialAttributesChange("custom:qrcodes_needs", e.target.value)}
                        >
                            {
                                ["1-100", "100-500", "500+"].map((need: string, i: number) => (
                                    <MenuItem key={i} value={need}>{need}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                )
            }
        }

        return (
            <>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t("First_name")}
                        value={credentials.attributes.name}
                        onChange={(e) => handleCredentialAttributesChange("name", e.target.value)}
                        fullWidth size={"small"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t("Surname")}
                        value={credentials.attributes.family_name}
                        onChange={(e) => handleCredentialAttributesChange("family_name", e.target.value)}
                        fullWidth size={"small"}
                    />
                </Grid>
                {/* QR Codes quantity */}
                <Grid item xs={12}>
                    {renderProperQuantityForm()}
                </Grid>
            </>
        )
    }

    const renderBusinessForm = () => {

        const handleChangeSso = () => {
            setCredentials({...credentials, attributes: {
                ...credentials.attributes, "custom:sso": !credentials.attributes["custom:sso"]
            }})
        }

        return (
            <Grid item xs={12}>
                <Collapse in>
                    <Paper variant={"outlined"}>
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            {renderMandatoryForm()}

                            <Grid item xs={12}>
                                <TextField
                                    label={t("business_name")}
                                    value={credentials.attributes["custom:company"]}
                                    onChange={(e) => handleCredentialAttributesChange("custom:company", e.target.value)}
                                    fullWidth size={"small"}
                                    required
                                />
                            </Grid>

                            {/* Business type */}
                            <Grid item xs={12}>
                                <FormControl required fullWidth size={"small"}>
                                    <InputLabel id={"compIndus"}>{t("company_industry")}</InputLabel>
                                    <Select
                                        required
                                        label={t("company_industry")}
                                        labelId={"compIndus"}
                                        value={credentials.attributes["custom:company_industry"]}
                                        onChange={(e) => handleCredentialAttributesChange("custom:company_industry", e.target.value)}
                                    >
                                        {
                                            companyIndustries.map((el: string, i: number) => (
                                                <MenuItem key={i} value={t(el, { lng: "en" })}>{t(el)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Departments */}
                            <Grid item xs={12}>
                                <FormControl required fullWidth size={"small"}>
                                    <InputLabel id={"compServ"}>{t("company_service")}</InputLabel>
                                    <Select
                                        required
                                        label={t("company_service")}
                                        labelId={"compServ"}
                                        value={credentials.attributes["custom:company_service"]}
                                        onChange={(e) => handleCredentialAttributesChange("custom:company_service", e.target.value)}
                                    >
                                        {
                                            companyServices.map((el: string, i: number) => (
                                                <MenuItem key={i} value={t(el, { lng: "en" })}>{t(el)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Your position */}
                            <Grid item xs={12}>
                                <FormControl fullWidth size={"small"}>
                                    <InputLabel id={"compRole"}>{t("company_position")}</InputLabel>
                                    <Select
                                        label={t("company_position")}
                                        labelId={"compRole"}
                                        value={credentials.attributes["custom:company_position"]}
                                        onChange={(e) => handleCredentialAttributesChange("custom:company_position", e.target.value)}
                                    >
                                        {
                                            companyRoles.map((el: string, i: number) => (
                                                <MenuItem key={i} value={t(el, { lng: "en" })}>{t(el)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* SSO */}
                            <Grid
                                item xs={12}
                                sx={{
                                    display: (
                                        (!!credentials.attributes["custom:qrcodes_needs"] && credentials.attributes["custom:qrcodes_needs"] === "500+") ||
                                        (!!product && (product === "packaging" || (product === "vcards" || product === "standalone")))
                                    ) ? "inline-block"
                                    : "none"
                                }}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                       checked={credentials.attributes["custom:sso"]}
                                       onChange={() => handleChangeSso()}
                                    />}
                                    label={t("sso")}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Collapse>
            </Grid>
        )
    }

    // Button render
    const renderButton = () => {

        const canProceed =
            ((
                !!credentials.attributes.name &&
                !!credentials.attributes.family_name &&
                !!credentials.attributes["custom:company"] &&
                !!credentials.attributes["custom:company_industry"] &&
                !!credentials.attributes["custom:company_service"]
            ) && (
                (!!product && product === "packaging" && !!credentials.attributes["custom:sku_needs"]) ||
                (!!product && (product === "vcards" || product === "standalone")) ||
                !!credentials.attributes["custom:qrcodes_needs"])
            )

        return (
            <>
                <Grid item xs={12}>
                    <Button
                        variant={"contained"}
                        disableElevation
                        size={"large"}
                        disabled={!canProceed || loading}
                        fullWidth
                        onClick={() => signup()}
                    >
                        {!canProceed ? t("missing_info_fields") : loading ? <CircularProgress /> :  t("complete_signup")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color={"secondary"}
                        size={"large"}
                        disabled={loading}
                        fullWidth
                        onClick={() => handleBack()}
                    >
                        {t("Back")}
                    </Button>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid item xs={12} container spacing={1}>
                {renderBusinessForm()}
                {renderButton()}
            </Grid>
        </>
    )
}