import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import { Container} from '@mui/material';
import { useTranslation } from 'react-i18next'
import {LoadingCircle} from "@components";
import {useUser} from "@context";

// This page component is made to auto-refresh the User and its credits once the checkout is complete
// It will make multiple calls to the API in order to refresh the whole console UI
// Once a specific number of cycles have been completed, it will redirect to the home screen
// --------------------------
// Credits are on a special place here, not fetched but will be fetched in PermissionsProvider once user has changed
// ComplexUser will be fetched by AuthenticationProvider once user has changed
export default function BillingCheckoutSuccess() {

    const {t} = useTranslation(['common']);
    const {getUser, getBillingInvoice} = useUser()
    const history = useHistory()
    const fetchUserRef = useRef<any>()
    const progressRef = useRef<any>()

    const [progress, setProgress] = useState<number>(0)
    const [iterations, setIterations] = useState<number>(0)

    // Fetches user
    useEffect(() => {

        fetchUserRef.current = setInterval(() => {
            fetchUser()
        }, 2000);

        progressRef.current = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 13));
        }, 900);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (iterations >= 3) {
            clearInterval(fetchUserRef.current)
            clearInterval(progressRef.current)
            history.replace({
                pathname: "/",
                state: {
                    checkout: true
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iterations]);

    useEffect(() => {
        if (progress >= 100) clearInterval(progressRef.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressRef]);


    const fetchUser = () => {
        if (iterations < 3) {
            getUser()
            getBillingInvoice()
            setIterations((prevIterations) => prevIterations + 1)
        } else return
    }

    return (
        <>
            <Container maxWidth={false} style={{ height: "100vh", backgroundColor: "#e8e8e8" }}>
                <LoadingCircle
                    loadingValue={progress >= 100 ? null : progress}
                    message={t("processing_your_order")}
                    subtitle={t("please_stay_here")}
                />
            </Container>
        </>
    )
}
