import React from "react"
// MUI
import {Box, Container, Divider, Grid, Paper, Typography} from "@mui/material";
// Components
import {Button} from "@components";
import BrandsCarousel from "@components/BrandsCarousel/BrandsCarousel";
import SignupDevMenu from "@/layout/components/devMenu/SignupDevMenu";
import AuthenticationContentPreview from "@/Views/Authentication/components/AuthenticationContentPreview";
import AuthenticationUnsecuredPage from "@/Views/Authentication/components/AuthenticationUnsecuredPage";
// ASSETS
import Background from "@assets/img/background_login.webp";
// Hooks
import i18next from "i18next";
import {useSnackbar} from "@hooks";

// Simply renders the UI for the SIGNUP/LOGIN/FORGOT-PWD pages
export default function AuthenticationWrapper(props: any) {

    const {
        signup, forgotPassword,
        // SIGNUP
        isPlanFunnel, product,
        credentials, setCredentials,
        // SSO
        logoSrc,
        // MANDATORY
        children, t
    } = props

    const {handleError} = useSnackbar()

    const styles = {
        container: {
            minHeight: "100vh",
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            px: "0!important"
        },
        logoGridItem: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        logo: {
            height: isPlanFunnel ? 75 : 110,
            width: "auto",
            margin: 32,
            filter:"drop-shadow(0 0 0.75rem #afc928)"
        },
        genericBrandsCarouselWrapper: {
            postion: 'absolute',
            width: "inherit",
            // backgroundColor: "red",
            top: 0,
        },
        mainPaper: {
            position: "relative",
            minHeight: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        translationBox: {
            padding: '24px',
            textAlign: 'center',
        },
    }

    const renderTranslationSection = () => {

        const toggleLang = (e: any) => {
            let lng = e.target.value
            i18next.changeLanguage(lng, (err, t) => {
                if (err) handleError(`${err}`);
                t('key');
            }).then();
        }

        return (
            <Box sx={styles.translationBox}>
                <Button text small value={"fr-FR"} onClick={toggleLang}>Français</Button>&nbsp;&nbsp;
                <Button text small value={"en-EN"} onClick={toggleLang}>English</Button>&nbsp;&nbsp;
                <Button text small value={"es-ES"} onClick={toggleLang}>Español</Button>&nbsp;&nbsp;
                <Button text small value={"de-DE"} onClick={toggleLang}>Deutsch</Button>
            </Box>
        )
    }


    if (window.self !== window.top) return <AuthenticationUnsecuredPage />

    return (
        <>
            <Container maxWidth={false} sx={styles.container}>
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item md={6} sx={styles.logoGridItem}>
                        <img
                            draggable={false}
                            src={!!logoSrc ? logoSrc : "/assets/logo-unitag.svg"}
                            alt={"login logo"}
                            height={isPlanFunnel ? 100 : 200} width={isPlanFunnel ? 225 : 450}
                            style={styles.logo}
                        />
                        {signup && <AuthenticationContentPreview {...{isPlanFunnel, product, t}} />}
                    </Grid>
                    <Grid item xs={12} sm={11} md={6}>

                        <Paper elevation={0}>
                            {
                                (signup && !isPlanFunnel) &&
                                <Box sx={styles.genericBrandsCarouselWrapper}>
                                    <BrandsCarousel tier={"platinum"} />
                                </Box>
                            }
                            <Paper elevation={0} sx={styles.mainPaper}>
                                <Grid container justifyContent={"center"} spacing={3}>
                                    <Grid item xs={10} sm={8} md={9} lg={7}>
                                        {
                                            (signup && !isPlanFunnel) &&
                                            <Typography variant="body1" sx={{ color: "#b1b1b1"}} align="center">
                                                {t("like_our_partners")}
                                            </Typography>
                                        }
                                        <Typography variant="h4" sx={{ mb: 4 }} align="center">
                                            {signup ? t("Signup_to_unitag") : forgotPassword ? t("Reset_password") : t("Login_to_unitag")}
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={10} sm={8} md={9} lg={7}>
                                        {children}
                                    </Grid>
                                    <Grid item xs={10} sm={8} md={9} lg={7}>
                                        {renderTranslationSection()}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>

                {signup && <SignupDevMenu {...{credentials, setCredentials, t}} />}
            </Container>
        </>
    )
}