import React from "react"
import {
    Box,
    Button, ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, InputLabel, MenuItem,
    Paper, Select, Stack,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {Send, Upload} from "@mui/icons-material";
import {regexEmail} from "@functions";

export default function SupportDialog(props: any) {

    const {
        open, onClose,
        onSend,
        supportRequest, setSupportRequest,
        onDropFile, onDeleteFile, uploadedFiles,
        loading,
        signupIssue,
        t
    } = props

    const styles = {
        dialog: {
            position: 'absolute',
            right: 0,
            bottom: 50
        },
        fileButton: {
            width: "100%",
            objectFit: "cover",
            height: 60,
            maxHeight: 60,
            maxWidth: "100%",
            p: 1,
            borderRadius: 5,
            overflow: "hidden",
            border: "1px solid transparent",
            transition: ".2s ease-in-out",
            "&:hover": {
                borderColor: "primary.main"
            }
        },
        importPaper: {
            p: 2,
            transition: ".2s ease-in-out",
            "&:hover": {
                backgroundColor: "#00000010"
            }
        },
        importPaperSubtitle: { fontSize: 14, color: "grey", fontWeight: 300 },
        imagesPaper: { p: 2, mt: 1, maxHeight: 300, overflowY: "scroll" }
    }

    const returnFilesList = () => {

        const isImageType = (type: any) => {
            return type === "png" || type === "jpg" || type === "jpeg" || type === "webp";
        }

        return (
            <Grid container spacing={1}>
                {
                    !!uploadedFiles && uploadedFiles.map((image: any, i: number) => (
                        <Grid item xs={12} md={3}>
                            <Tooltip title={t("support_dialog_delete")} arrow>
                                <ButtonBase onClick={() => onDeleteFile(image)} sx={styles.fileButton}>
                                    {
                                        isImageType(image.url.split(".").slice(-1)[0]) ?
                                            <img
                                                alt={image.url}
                                                draggable={false}
                                                key={i}
                                                src={image.url}
                                                style={{
                                                    maxHeight: 60,
                                                }}
                                            />
                                            :
                                            <Box sx={{ height: 60, alignItems: "center", display: "flex" }}>
                                                <Stack direction={"column"}>
                                                    <Typography>
                                                        .{image.url.split(".").slice(-1)[0]}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                    }
                                </ButtonBase>
                            </Tooltip>
                        </Grid>
                    ))
                }
            </Grid>
        )
    }

    const renderSupportForm = () => {

        const renderMessage = () => {
            return (
                <Grid item xs={12}>
                    <TextField
                        required
                        placeholder={t("support_dialog_message_label")}
                        multiline rows={5}
                        value={supportRequest.message}
                        fullWidth
                        onChange={(e: any) => setSupportRequest({...supportRequest, message: e.target.value})}
                    />
                </Grid>
            )
        }

        const renderMail = () => {
            return (
                <Grid item xs={12}>
                    <TextField
                        required={signupIssue}
                        error={!!supportRequest?.email && !regexEmail(supportRequest.email)}
                        size={"small"}
                        label={t("support_dialog_contact_email")}
                        fullWidth
                        value={supportRequest.email}
                        placeholder={t("support_dialog_email_placeholder")}
                        onChange={(e: any) => setSupportRequest({...supportRequest, email: e.target.value})}
                    />
                </Grid>
            )
        }

        const renderReason = () => {
            if (signupIssue) return <></>
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth size={"small"}>
                        <InputLabel id={"support-reason"}>{t("support_dialog_reason")}</InputLabel>
                        <Select
                            labelId={"support-reason"}
                            label={t("support_dialog_reason")}
                            value={supportRequest.reason}
                            onChange={(e: any) => setSupportRequest({...supportRequest, reason: e.target.value})}
                        >
                            {["general_issue", "billing_issue", "product_issue", "feature_issue"].map((issue: any, i: number) => (
                                <MenuItem key={i} value={t(issue)}>{t(issue)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )
        }

        const renderFileImport =  () => {
            return (
                <>
                    <Grid item xs={12}>
                        <label htmlFor={"support-files"} style={{ cursor: "pointer" }}>
                            <Paper variant={"outlined"} sx={styles.importPaper}>
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Upload />
                                    <Typography>
                                        {t("support_dialog_import_files")} <br/>
                                        <span style={styles.importPaperSubtitle}>{t("support_dialog_import_restrictions")}</span>
                                    </Typography>
                                </Stack>
                            </Paper>
                        </label>
                        <input
                            multiple
                            disabled={loading}
                            // accept="image/*"
                            id={"support-files"}
                            name={"file"}
                            type={"file"}
                            onChange={onDropFile}
                            style={{ display: "none" }}
                        />

                        <Paper variant={"outlined"} sx={styles.imagesPaper}>
                            {!!uploadedFiles.length ? returnFilesList() : <Typography variant={"body2"}>{t("support_dialog_no_image")}</Typography>}
                        </Paper>
                    </Grid>
                </>
            )
        }

        return (
            <Grid container spacing={1} justifyContent={"center"}>
            <Grid item xs={12}>
                    <Typography variant={"body2"} gutterBottom>
                        {t("support_dialog_intro")}
                    </Typography>
                </Grid>
                {renderMail()}
                {renderReason()}
                {renderMessage()}
                {renderFileImport()}
            </Grid>
        )
    }

    return (
        <Dialog {...{open, onClose}} maxWidth={"xs"} fullWidth PaperProps={{ sx: styles.dialog }}>
            <DialogTitle>{signupIssue ? t("support_dialog_signup") : t("support_dialog_title")}</DialogTitle>
            <DialogContent>{renderSupportForm()}</DialogContent>
            <DialogActions sx={{ px: 3, pb: 2  }}>
                <Button onClick={onClose} sx={{ color: "grey" }}>
                    {t("Cancel")}
                </Button>
                <Button
                    variant={"contained"} disableElevation
                    onClick={onSend}
                    endIcon={<Send />}
                    disabled={(signupIssue && !supportRequest.email) || !supportRequest.message || loading || (!!supportRequest.email && !regexEmail(supportRequest.email))}
                >
                    {t("support_dialog_send")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
