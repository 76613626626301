// BASE
import React from 'react';
import {Link} from "react-router-dom";
// Material UI
import {
    AppBar as MUIAppBar,
    AppBarProps as MUIAppBarProps,
    Toolbar,
    Typography,
    Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from '@mui/material/styles';
//TRANSLATION
import {useTranslation} from "react-i18next";
// Unitag UI
import {Button, TourPopup} from '@components';
// Components
import NotificationCenter from "./components/notificationMenu/notificationCenter";
import DevMenu from "./components/devMenu/DevMenu";
import LanguageMenu from './components/languageMenu/LanguageMenu';
import NavbarDrawer from "./components/navbarDrawer/NavbarDrawer";
import AccountMenu from "./components/accountMenu/AccountMenu";
// Context (new)
import {useUser} from "@context";
import {useTour} from "@/Context/Tour/TourProvider";
import {useStyles} from "@/Context/Ui/DashboardUiProvider";
import {useAuthentication} from "@/Context/Authentication/AuthenticationProvider";

// Private
const drawerWidth = 300
const {REACT_APP_ENV} = process.env;

// Types & Props
interface AppBarProps extends MUIAppBarProps {
    open?: boolean;
    children: any,
}

const AppBar = styled(MUIAppBar, {shouldForwardProp: (prop) => prop !== 'open'})<AppBarProps>(
    ({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

export default function Navbar({children}: any) {

    const {logout} = useAuthentication()
    const {logo} = useStyles()
    const {homeTour, setHomeTour, homeTourSteps} = useTour()
    const {t} = useTranslation(['navigation', 'common']);

    const styles = {
        // navbar
        unitagLogo: {height: '35px', width: 'auto', fill: '#ffffff'},
        // drawer
        listHeader: {justifyContent: 'flex-end'},
        drawerStaticDivider: {my: "20px"},
        drawerAppButtons: {my: "5px"},
        // menu
        menuTextAnimationWrapper: {
            animation: "spin 1s linear",
            "@keyframes spin": {
                "10%": {
                    transform: "rotate(10deg)",
                },
                "50%": {
                    transform: "rotate(-10deg)",
                },
                "100%": {
                    transform: "rotate(0deg)",
                },
            }
        },


        accountMenuPrimary: {
            fontWeight: 400,
            fontSize: '14px',
            color: '#676767',
        },
        accountMenuSecondary: {
            fontWeight: 300,
            fontSize: '14px',
            color: '#676767',
        },
        accountMenuElearning: {
            minWidth: '180px',
            fontWeight: 700,
            fontSize: '14px',
            color: 'secondary.main',
        },

        accountMenuIcons: {
            minWidth: 24
        },

        accountMenuButton: {
            backgroundColor: homeTour === 2 ? "rgba(175,201,40,0.4)" : "transparent",
            boxShadow: homeTour === 2 ? "0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .3)" : "none",
            position: "relative!important",
            pointerEvents: !!homeTour ? "none" : "all"
        }
    };

    const {user, organisationAsMember} = useUser()

    // Sub-menus & drawers opening states
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<null | HTMLElement>(null);

    // Logout
    const handleLogout = () => {
        setAccountMenuAnchor(null)
        logout()
    }

    if (!user) return <>{children}</>

    return (
        <>
            <AppBar position="fixed" color="primary">
                <Toolbar>

                    {/* Left section */}
                    <Button text component={Link} to="/" aria-label="home">
                        <img
                            src={logo}
                            alt={"logo"}
                            style={{
                                height: "35px",
                                width: "auto"
                            }}
                        />
                    </Button>

                    {
                        window.self !== window.top ?
                            null
                            : <Button
                                startIcon={<MenuIcon/>}
                                text
                                spacing
                                aria-label="menu"
                                onClick={() => setDrawerOpen(true)}
                            >
                                <Box sx={styles.menuTextAnimationWrapper}>
                                    {t("Menu")}
                                </Box>
                            </Button>

                    }

                    <Typography variant="h6" component={"p"} flexGrow={1}></Typography>

                    {/* Right section */}
                    <DevMenu {...{REACT_APP_ENV}} />

                    {
                        window.self !== window.top ?
                            null
                            : <>
                                <LanguageMenu/>

                                <NotificationCenter/>

                                <TourPopup
                                    step={2}
                                    steps={homeTourSteps}
                                    state={homeTour}
                                    setState={setHomeTour}
                                    offset={{ x: 0, y: 15 }}
                                    tourApiIdentifier={"homepage"}
                                >
                                        <AccountMenu
                                            anchor={accountMenuAnchor}
                                            onOpen={(e: any) => setAccountMenuAnchor(e.currentTarget)}
                                            onClose={() => setAccountMenuAnchor(null)}
                                            {...{setAccountMenuAnchor, user, organisationAsMember, handleLogout, styles, t}}
                                        />
                                </TourPopup>
                            </>
                    }


                </Toolbar>
            </AppBar>

            <Box style={{marginTop: "65px", marginLeft: "64px"}}>
                {children}
            </Box>

            {
                window.self !== window.top ?
                    null
                    : <NavbarDrawer
                        open={drawerOpen}
                        setOpen={setDrawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        {...{drawerWidth, styles, t}}
                    />

            }
        </>
    );
}
