
function createData(label: string, standard: any, gold: any, platinum: any) {
    return {label, standard, gold, platinum};
}

function PlansDetails(t: any) {

    return [
        {
            title: t("dynamic_qr_codes"),
            rows: [
                createData(t("QR_Included_in_plan"), "100", "500", "Unlimited"),
                createData(t("Analytics"), t("2_weeks"), t("3_months"), t("3_years")),
                createData(t("Price_per_scan"), t("Free"), t("Free"), t("Free")),
                createData(t("Full_customization"), true, true, true),
                createData(t("Reusable_templates"), true, true, true),
                createData(t("Quick_analytics"), true, true, true),
                createData(t("HD_downloads"), "Unlimited", "Unlimited", "Unlimited"),
                createData(t("Document_hosting"), true, true, true),
                createData(t("Dynamic_pro"), false, true, true),
                createData(t("Campaign_management"), false, true, true),
                createData(t("Filters"), false, true, true),
                createData(t("Custom_domain_names"), false, t("extra_module"), t("extra_module")),
                createData(t("Healthchecks"), false, true, true),
            ]
        },
        {
            title: t("storage"),
            rows: [
                createData(t("storage_capacity"), "100 Mb*", "300 Mb*", "1 Gb*")
            ],
            subtext: t("storage_subtext")
        },
        {
            title: t("vcard_pro"),
            rows: [
                createData(t("Included_in_plan"), t("extra_module"), t("extra_module"), t("extra_module"))
            ]
        },
        {
            title: t("domains"),
            rows: [
                createData(t("Included_in_plan"), false, true, true),
                createData(t("Price_per_domain"), false, 7.99, 7.99),
                createData(t("Sharing_option"), false, false, true)
            ]
        },
        {
            title: t("organisations"),
            rows: [
                createData(t("Price_per_activation"), false, 4.79, 8.29),
                createData(t("Role_management"), false, true, true),
                createData(t("Sub_orgs"), false, false, true)
            ]
        },
        {
            title: t("atlas"),
            rows: [
                createData(t("Included_in_plan"), true, true, true),
                createData(t("Qrcodes"), true, true, true),
                createData(t("Qrcodes_advanced"), false, true, true),
                createData(t("vCard Pro"), true, true, true),
                createData(t("Packaging"), false, false, true)
            ]
        },
        {
            title: t("campaigns"),
            rows: [
                createData(t("Included_in_plan"), false, true, true),
                createData(t("Campaign_performance_analytics"), false, true, true),
                createData(t("Sub_campaigns"), false, false, true)
            ]
        },
        {
            title: t("unique_qr_codes"),
            rows: [
                createData(t("Unique_QRCode"), t("extra_module"), t("extra_module"), t("extra_module")),
                createData(t("Start_price"), 0.0126, 0.0126, 0.0126),
                createData(t("Medium_price"), 0.0096, 0.0096, 0.0096),
                createData(t("larg_price"), 0.0084, 0.0084, 0.0084),
                createData(t("enterprise_volume"), 0.0072, 0.0072, 0.0072),
            ]
        },
        {
            title: t("sso"),
            rows: [
                createData(t("SSO_feature"), false, false, t("extra_module")),
            ]
        },
        {
            title: t("packaging"),
            rows: [
                createData(t("Included_in_plan"), false, t("extra_module"), t("extra_module")),
                createData(t("Precise_location_module"), false, true, true),
                createData(t("SKU_management"), false, true, true),
                createData(t("Brands_Ranges_design"), false, true, true),
            ]
        },
    ]
}

export default PlansDetails