import React, {createContext, useContext, useEffect, useState} from "react"
import {useHistory, useLocation} from "react-router-dom";
// context
import {useUser} from "@context";
// loading
import {LoadingCircle} from "@components";
// hooks
import {useTranslation} from "react-i18next";
import {useSnackbar} from "@hooks";
// AWS
import {Amplify, Auth} from "aws-amplify";
// redirections & funnels (cross-providers functions)
import {redirectByPath, returnFirstVisitFunnel} from "@/functions/authenticationFunctions";
// sso data
import ssoProviders from "@/constants/SSO";
// API
import {APIPostUnauthentified} from "@/API/apiCalls";
// constants
import localstorageItems from "@/constants/localstorageItems";

const {
    REACT_APP_API_URL,
    REACT_APP_COGNITO_POOL_ID,
    REACT_APP_COGNITO_APP_ID,
    REACT_APP_COGNITO_APP_DOMAIN,
    REACT_APP_COGNITO_APP_REDIRECT_SIGNIN,
    REACT_APP_COGNITO_APP_REDIRECT_SIGNOUT
} = process.env;

// Setting up AWS
Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
            domain: REACT_APP_COGNITO_APP_DOMAIN,
            clientID: REACT_APP_COGNITO_APP_ID,
            responseType: "token",
            scope: ["email","openid","profile"],
            redirectUri: REACT_APP_COGNITO_APP_REDIRECT_SIGNIN,
            redirectSignIn: REACT_APP_COGNITO_APP_REDIRECT_SIGNIN,
            redirectSignOut: REACT_APP_COGNITO_APP_REDIRECT_SIGNOUT
        }
    }
});

// Credentials initial state
const initSignupCredentials = {
    username: '',
    password: '',
    passwordConf: '',
    attributes: {
        email: '',
        name: '',
        family_name: '',
        "custom:usage": 'Business',
        "custom:company": '',
        "custom:company_position": '',
        "custom:company_size": '',
        "custom:company_industry": '',
        "custom:preferred_language": '',

        // NEW FIELDS
        "custom:company_service": '',
        "custom:qrcodes_needs": '',
        "custom:sku_needs": '',
        "custom:product_funnel": '',
        "custom:sso": false,
        "custom:accepted_terms": false,
        "custom:accepted_newsletter": false
    }
}

const initLoginCredentials = {
    username: "",
    password: "",
    passwordRepeated: "",
}

const initResetCredentials = {
    username: "",
    password: "",
    verificationCode: "",
}

// Context setup
const AuthenticationContext = createContext<any>(null)

export const useAuthentication = () => useContext(AuthenticationContext)

export function AuthenticationProvider({children}: any) {

    const {user, getUser, getComplexUser, credentials, setCredentials, getOrganisationAsMember} = useUser()
    const {t} = useTranslation("common")
    const {handleError, handleSuccess, handleWarning} = useSnackbar()
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();

    // This is the "global" loading state displaying the big-ass spinner - use with caution
    const [loading, setLoading] = useState<boolean>(true);
    // signup
    const [signupStep, setSignupStep] = useState<string | null>(null)
    const [signupCredentials, setSignupCredentials] = useState<any>(initSignupCredentials)
    const [signupFunnel, setSignupFunnel] = useState<string | null>(null)
    // login
    const [loginCredentials, setLoginCredentials] = useState<any>(initLoginCredentials)
    // 2FA
    const [client2FA, setClient2FA] = useState<any | null>(null);
    const [is2FACodeValid, setIs2FACodeValid] = useState(false);
    const [is2FACodeChecked, setIs2FACodeChecked] = useState(false);
    // forgot password
    const [resetCredentials, setResetCredentials] = useState(initResetCredentials);
    // SSO
    const [sso, setSso] = useState<null | { provider: string, logo: string }>(null)

    useEffect(() => {
        // URL reading
        const path = window.location.pathname;
        getUser().then(() => {}, () => {
            setLoading(true)
            redirectLoggedOutUser(path)
            handleCloseLoading()
        }).finally(() => {
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials]);

    // Hydrate contexts and states
    // THIS is what happens immediately when a user is successfully logged
    useEffect(() => {
        if (!!user) {
            getComplexUser()
            if (!!user.org) getOrganisationAsMember()
            handleCloseLoading()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // This can come in handy if we need to add timeout
    const handleCloseLoading = () => {
        setLoading(false)
    }

    // Hard reset
    const resetAllCredentials = () => {
        setSignupCredentials(initSignupCredentials)
        setLoginCredentials(initLoginCredentials)
        setResetCredentials(initResetCredentials)
    }

    // Checkers and redirections
    const redirectLoggedOutUser = (path: any) => {
        const product = query.get("product");
        const vcardsNumber = query.get("vcards-number");

        switch (path) {
            case "/signup":
                if (product) {
                    if ((product === "buy-vcards" || product === "standalone") && !!vcardsNumber) {
                        history.replace(`/signup?product=${product}&vcards-number=${vcardsNumber}`);
                    } else {
                        history.replace(`/signup?product=${product}`);
                    }
                    break;
                }
                history.replace("/signup");
                break;
            case "/google_sso":
                history.replace("/google_sso");
                break;
            default:
                if (product) {
                    history.replace(`/login?product=${product}`);
                    return;
                }
                history.replace("/login");
        }
    }

    const checkLoggedInUser = () => {
        Auth.currentSession().then((sess) => {
            sess.getIdToken();
            // eslint-disable-next-line no-restricted-globals
            history.push("/");
        })
    }

    // Signup
    const signup = async () => {

        let res

        const supporttedLanguages = [
            "en-US", "fr-FR", "en-GB", "es-ES"
        ]

        let usedLanguage = window.localStorage.i18nextLng

        if (!usedLanguage || supporttedLanguages.indexOf(usedLanguage) === -1) usedLanguage = "en-EN"


        let attributes = {
            ...signupCredentials.attributes,
            "custom:preferred_language": usedLanguage,
            email: signupCredentials.username,
            "custom:accepted_terms": String(signupCredentials.attributes["custom:accepted_terms"]),
            "custom:sso": String(signupCredentials.attributes["custom:sso"]),
            "custom:accepted_newsletter": String(signupCredentials.attributes["custom:accepted_newsletter"]),
        }


        await Auth.signUp({
            username: signupCredentials.username,
            password: signupCredentials.password,
            attributes: attributes
        }).then(() => {
            res = true
            pingLeads(attributes)
            handleSuccess(t("Account_creation_success"))
            setSignupStep("code")
        }, reason => {
            handleError(reason.message)
            if (reason.message === "An account with the given email already exists.") {
                setSignupStep("email-taken")
            }
        }).catch(() => handleError(t("error_get_identification")))

        return res
    }

    // ping slack by sending attributes to the API
    const pingLeads = (attributes: any) => {
        if (!attributes) return undefined
        APIPostUnauthentified(REACT_APP_API_URL + "/slack/new-lead", attributes).then(() => {}, () => {
            handleWarning("Unable to process signup details. Your console tour may not appear as desired.")
        }).catch(() => {
            handleWarning("Unable to process signup details. Your console tour may not appear as desired.")
        })
    }

    const verifyCode = async (code: any) => {

        let res

        await Auth.confirmSignUp(signupCredentials.username, code).then(() => {
            res = true
            handleSuccess(t("success_verifying_code"))
        }).catch(() => {
            handleError(t("Verification_code_error"))
        })

        return res
    }

    const signIn = (product?: any, vcardsNumber?: any) => {
        setLoading(true)
        Auth.signIn(signupCredentials.username, signupCredentials.password)
            .then((awsUser: any) => {
                setCredentials({
                    refresh_token: '',
                    id_token: awsUser.signInUserSession.idToken.jwtToken,
                })
                setSignupFunnel(returnFirstVisitFunnel(signupCredentials.attributes, product))
                window.localStorage.setItem(localstorageItems.signupFunnel, returnFirstVisitFunnel(signupCredentials.attributes, product))
                // clear signup credentials for, you know, security
                setSignupCredentials(initSignupCredentials)
                // Redirect accordingly
                redirectByPath(product, history, vcardsNumber);
            }, reason => {
                handleError(reason.message)
            }).catch(() => handleError(t("error_get_identification")))
    }

    const handleSignupStep = () => {
        setSignupStep("info")
    }

    // Login/Logout
    const login = async (e: any, loginCreds: any) => {
        // skip the submission
        e.preventDefault()

        let res

        // auth the stuff
        await Auth.signIn(loginCreds.username, loginCreds.password).then(value => {
            if (value.challengeName !== undefined) {
                if (value.challengeName === "NEW_PASSWORD_REQUIRED") {
                    Auth.completeNewPassword(
                        value,
                        loginCreds.password,
                        {
                            email: loginCreds.username,
                        }
                    ).then(value => {
                        setCredentials({
                            refresh_token: "",
                            id_token: value.signInUserSession.idToken.jwtToken,
                        });
                        resetAllCredentials()
                    });
                } else if (value.challengeName === "SOFTWARE_TOKEN_MFA") {
                    // Handle 2FA, present 2FA dialog
                    res = value
                    // setTmpClient(value);
                    // setOpen2FA(true)
                }
            } else {
                // Everything is ok
                setCredentials({
                    refresh_token: "",
                    id_token: value.signInUserSession.idToken.jwtToken,
                });
                resetAllCredentials()
            }
        }, reason => {
            res = "NO_LOGIN"
            handleError(`${t(reason.message)}`)
        });

        return res
    }

    const handleOpen2FA = (client: any) => {
        setClient2FA(client)
    }

    const check2FAChange = (code: any) => {
        if (code.length < 6 || !client2FA) return undefined

        Auth.confirmSignIn(client2FA, code, 'SOFTWARE_TOKEN_MFA')
            .then(value => {
                setCredentials({
                    refresh_token: "",
                    id_token: value.signInUserSession.idToken.jwtToken,
                });

                Auth.rememberDevice().then();
                resetAllCredentials()
                // redirectByPath();
            }, reason => {
                // Not from any remembered device
                // TODO: should this be improved? Is that secure to accept any device?
                if (reason.message === "Invalid device key given.") {
                    Auth.currentUserPoolUser().then(user => {
                        user.getCachedDeviceKeyAndPassword();
                        Auth.rememberDevice().then();
                    })

                    // redirectByPath();
                } else {
                    setIs2FACodeChecked(true);
                    setIs2FACodeValid(false);
                    handleError(`${t(reason.message)}`)
                    setLoading(false);
                }
            })
    }

    const logout = () => {
        setLoading(true)
        Auth.signOut().then(() => {
            resetAllCredentials()
            history.go(0);
            history.push("/login");
        });
    }

    // Forgot password
    const sendForgotPasswordCode = async () => {

        let res = false

        await Auth.forgotPassword(resetCredentials.username).then(() => {
            handleSuccess(t("Verification_code_sent"))
            res = true
        }).catch(err => {
            if (err.code === "UserNotFoundException") handleError(t("error_user_not_found"))
            else handleError(`${t("unknown_error")}: ${err.message}`)
        });

        return res
    }

    const resetPassword = () => {
        Auth.forgotPasswordSubmit(resetCredentials.username, resetCredentials.verificationCode, resetCredentials.password)
            .then(() => {
                handleSuccess(t("Password_update_success"))
                Auth.signOut({ global: true }).then()
                setResetCredentials(initResetCredentials)
                history.push("/login");
            })
            .catch(err => {
                handleError(`${t("error_sending_password_reinitialisation")}: ${err.message}`)
            });
    }

    // SSO
    // // on login
    const setSsoDataFromPath = (path: any) => {
        // if path.includes(any of the initialPath) => setSso(provider, logo)
        ssoProviders.forEach((prov: any) => {
            prov.initialPath.forEach((ssoPath: any) => {
                if (path.includes(ssoPath)) setSso({ provider: prov.provider, logo: prov.logo });
            })
        })
    }

    const redirectSsoFromLogin = (email: any) => {
        // if email.includes(any on the emailDomain) => redirect to redirectionPath
        ssoProviders.forEach((prov: any) => {
            prov.emailDomain.forEach((domain: any) => {
                if (email.includes(domain)) window.location.replace(prov.redirectionPath)
            })
        })
    }

    const loginWithSso = () => {
        if (!sso || !sso.provider) return undefined
        Auth.federatedSignIn({customProvider: sso.provider}).then()
    }

    // Render
    const renderChildren = () => {
        if (loading) return <LoadingCircle />
        return children
    }

    return (
        <AuthenticationContext.Provider value={{
            checkLoggedInUser,
            logout,
            signup,
            signupStep, setSignupStep, signupCredentials, setSignupCredentials,
            handleSignupStep, verifyCode,
            signupFunnel,

            signIn,
            // Login
            login,
            loginCredentials, setLoginCredentials,
            // 2FA
            handleOpen2FA ,check2FAChange, client2FA,
            is2FACodeChecked, is2FACodeValid,
            // forgot password
            resetCredentials, setResetCredentials,
            sendForgotPasswordCode, resetPassword,
            // SSO
            sso,
            loginWithSso,
            setSsoDataFromPath,
            redirectSsoFromLogin

        }}>
            {renderChildren()}
        </AuthenticationContext.Provider>
    )
}
