import React from 'react';
import PrivacyPolicyContent from "@components/Legal/PrivacyPolicyContent";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";

export default function PrivacyPolicy() {

    const {t} = useTranslation(("common"))

    return (
        <>
            <Button component={Link} to={"/"}>
                {t("Back")}
            </Button>
            <PrivacyPolicyContent />
            <Button component={Link} to={"/"}>
                {t("Back")}
            </Button>
        </>
    )
}