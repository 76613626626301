import React from "react"
import {Box, Collapse, Paper, Stack, Typography} from "@mui/material";
import {CheckCircleOutline} from "@mui/icons-material";
import SignupStandard from "@assets/img/signup/signup-standard.png";
import SignupGold from "@assets/img/signup/signup-gold.png";
import SignupPlatinum from "@assets/img/signup/signup-platinum.png";
import SignupStandalone from "@assets/img/signup/signup-standalone.png";
import BrandsCarousel from "@components/BrandsCarousel/BrandsCarousel";

export default function AuthenticationContentPreview(props: any) {

    const {isPlanFunnel, product, t} = props

    const renderListElement = (listEl: any, color: string) => {
        return (
            <>
                <Stack direction="row" spacing={2}>
                    <CheckCircleOutline sx={{color: color }} />
                    <Typography variant={"body2"} sx={{ marginTop: "2px!important" }}>
                        {listEl}
                    </Typography>
                </Stack>
            </>
        )
    }

    const renderPlanPreview = (plan: any) => {
        return (
            <>
                <Box sx={{
                    backgroundImage: `url(${plan.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center 30%",
                    height: 250,
                    width: "100%!important",
                    borderRadius: "12px 12px 0 0"
                }}></Box>
                <Box p={4}>
                    <Typography align={"center"} fontSize={22} fontWeight={600} sx={{ color: plan.color }}>
                        {plan.title}
                    </Typography>
                    <Typography variant={"body1"} sx={{ my: 2 }}>
                        {plan.description}
                    </Typography>

                    {
                        plan.features.map((feat: any, i: number) => (
                            <Box key={i}>{renderListElement(feat, plan.color)}</Box>
                        ))
                    }
                </Box>
            </>
        )
    }

    const renderContentType = () => {

        const plans = {
            standard: {
                title: t("standard_title"),
                description: t("standard_description"),
                image: SignupStandard,
                features: [
                    t("standard_feature_1"),
                    t("standard_feature_2"),
                    t("standard_feature_3"),
                ],
                color: "#afc928"
            },
            gold: {
                title: t("gold_title"),
                description: t("gold_description"),
                image: SignupGold,
                features: [
                    t("gold_feature_1"),
                    t("gold_feature_2"),
                    t("gold_feature_3"),
                ],
                color: "#e39d00"
            },
            platinum: {
                title: t("platinum_title"),
                description: t("platinum_description"),
                image: SignupPlatinum,
                features: [
                    t("platinum_feature_1"),
                    t("platinum_feature_2"),
                    t("platinum_feature_3"),
                ],
                color: "#0077cc"
            },
            standalone: {
                title: t("standalone_title"),
                description: t("standalone_description"),
                image: SignupStandalone,
                features: [
                    t("standalone_feature_1"),
                    t("standalone_feature_2"),
                    t("standalone_feature_3"),
                ],
                color: "#00cc8f"
            },
        }

        switch (product) {
            case "standalone":
            case "buy-vcards": return renderPlanPreview(plans.standalone)
            case "standard-plan":
            case "standard": return renderPlanPreview(plans.standard)
            case "gold-plan":
            case "gold": return renderPlanPreview(plans.gold)
            case "platinum-plan":
            case "platinum": return renderPlanPreview(plans.platinum)
            default: return <></>
        }
    }

    const returnTier = () => {
        switch (product) {
            case "standalone":
            case "buy-vcards": return "vcards"
            case "standard-plan":
            case "standard": return "standard"
            case "gold-plan":
            case "gold": return "gold"
            case "platinum-plan":
            case "platinum":
            default: return "platinum"
        }
    }

    return (
        <>
            <Collapse in={isPlanFunnel} sx={{ display: "flex", width: "100%", maxWidth: "450px", minWidth: "250px" }}>
                <Paper
                    variant={"outlined"}
                    sx={{
                        width: "100%",
                        minHeight: "300px",
                        borderRadius: 3,
                        border: "none"
                    }}
                >
                    {renderContentType()}

                    <BrandsCarousel tier={returnTier()} />
                </Paper>
            </Collapse>
        </>
    )

}