import React, {useEffect, useState} from "react"
import {
    Alert, Box,
    Button,
    ButtonBase, Chip,
    Dialog,
    DialogContent,
    Grid, IconButton,
    Paper,
    Skeleton, Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTour} from "@/Context/Tour/TourProvider";
import {Close, Key} from "@mui/icons-material";
import {useSubscriptions} from "@hooks";
import {formatPrice} from "@functions";
import {PlansFrequencySwitch} from "@/Views/Plans/components";
import {Link, useHistory} from "react-router-dom";
import {APIPost} from "@api";
import {CreateCheckoutSessionResponse} from "@interfaces";
import {useStripe} from "@stripe/react-stripe-js";
import {usePermissions} from "@/Context/User/PermissionsProvider";
import {standaloneSubscription} from "@/constants/plans_en";

const {REACT_APP_API_URL} = process.env;

export default function FirstVisitDialog() {

    const {isFirstVisit, firstVisitFunnel, setFirstVisitFunnel, handleTourEnd} = useTour()
    const {t} = useTranslation(["tour", "pricing"])
    const {trueAccountType} = usePermissions()
    const history = useHistory()
    const stripe = useStripe();

    const [frequency, setFrequency] = useState("monthly")
    const [selectedPlan, setSelectedPlan] = useState<any>()

    let openDialog = (!!isFirstVisit || !!firstVisitFunnel) && history.location.pathname === "/"

    let displayedSubscriptions: any[] = []

    const {subscriptions, currency} = useSubscriptions(frequency)

    // TODO: Find a way to skip first visit funnel if client is on vCards/Standalone without SSO

    const styles = {
        planPaper: {
            buttonBase: {
                width: "100%", flexDirection: "row",
            },
            paper: {
                width: "inherit", transition: ".2s ease-in-out",
                "&:hover": {
                    backgroundColor: "#00000010"
                }
            }
        },
        skipText: {
            color: "grey", fontStyle: "italic", fontSize: 14, cursor: "pointer", textDecoration: "underline",
            "&:hover": {
                textDecoration: "underline"
            }
        }
    }

    useEffect(() => {
        if (!!displayedSubscriptions && !!displayedSubscriptions.length && !selectedPlan) setSelectedPlan(displayedSubscriptions[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedSubscriptions]);

    // Create new subscription on the fly (from /alacarte)
    const createSubscription = () => {

        let body = {
            subscriptions: [
                { subscription_type: selectedPlan.label.toLowerCase(), quantity: 1 }
            ],
            recurrence: frequency
        }

        // Call behavior defaults to /alacarte redirection to avoid creating friction with new users
        APIPost<CreateCheckoutSessionResponse>(REACT_APP_API_URL + "/billing/checkout_session", body).then(async (data) => {
            if (!!data.parsedBody) {
                if (data.parsedBody.stripe_session_id !== '') {
                    stripe?.redirectToCheckout({sessionId: data.parsedBody.stripe_session_id}).then()
                } else if (data.status === 500) history.push(selectedPlan.link)
            } else history.push(selectedPlan.link)
        }, () => {
            history.push(selectedPlan.link)
        });
    }

    // Renders
    const renderDialogTitle = () => {
        return (
            <Grid item xs={12}>
                <Typography fontWeight={800} variant={"h2"} component={"p"} align={"center"}>
                    {
                        !firstVisitFunnel ? t("dashboard_overview") : t("welcome_to_unitag")
                    }
                </Typography>
            </Grid>
        )
    }

    const renderDialogIntro = () => {

        if (!firstVisitFunnel) {
            return (
                <Grid item xs={12}>
                    <Typography align={"center"}>
                        {trueAccountType === standaloneSubscription ? t("first_visit_standalone") : t("first_visit_tutorial")}
                    </Typography>
                </Grid>
            )
        } else {

            const renderSsoInfo = () => {
                if (firstVisitFunnel.includes("-sso")) {
                    return (
                        <Alert severity={"info"} icon={<Key />} sx={{ my: 2 }}>
                            {t("sso_ping_sent")}
                        </Alert>
                    )
                }
            }

            const renderPackagingInfo = () => {
                if (firstVisitFunnel.includes("packaging-")) {
                    return (
                        <Typography sx={{ mt: 2 }} align={"center"}>
                            {t("first_visit_intro_packaging")}
                        </Typography>
                    )
                }
            }

            return (
                <Grid item xs={12}>
                    {/*<Typography align={"center"}>*/}
                    {/*    {t("we_recommend")}*/}
                    {/*</Typography>*/}
                    {renderPackagingInfo()}
                    {renderSsoInfo()}
                </Grid>
            )
        }
    }

    const renderDialogRecommendations = () => {

        const renderPlanPaper = (plan?: any, highlight?: boolean) => {

            if (!plan) return null

            return (
                <Grid item xs={12}>
                    <ButtonBase sx={styles.planPaper.buttonBase} onClick={() => setSelectedPlan(plan)}>
                        <Paper variant={"outlined"} sx={{
                            ...styles.planPaper.paper,
                            px: 2, pt: highlight ? 4 : 2, pb: highlight ? 4 : 2,
                            borderColor: selectedPlan?.label === plan.label ? "#afc928" : "divider.main",
                            backgroundColor: selectedPlan?.label === plan.label ? "#afc92820!important" : "transparent"
                        }}>
                            {
                                !!highlight &&
                                <Box sx={{ position: "absolute", top: 8, right: 0}}>
                                    <Chip
                                        size={"small"}
                                        label={t("we_recommend_plan")}
                                        sx={{
                                            backgroundColor: plan.label.toLowerCase() + ".main",
                                            color: "white",
                                            fontWeight: 600,
                                            borderRadius: "8px 0 0 8px"
                                        }}
                                    />
                                </Box>
                            }
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={8}>
                                    <Stack direction={"row"}>
                                        <IconButton
                                            sx={{
                                                pointerEvents: "none",
                                                borderRadius: 1,
                                                backgroundColor: plan.label.toLowerCase() + ".main",
                                                color: "white",
                                                mr: 2
                                            }}
                                        >
                                            {plan.icon}
                                        </IconButton>
                                        <Typography
                                            variant={"h5"}
                                            component={"p"}
                                            sx={{
                                                color: plan.label.toLowerCase() + ".main",
                                                fontWeight: 700,
                                                mt: "4px!important"
                                            }}
                                        >
                                            {plan.label}
                                        </Typography>
                                    </Stack>

                                    <Stack direction={"row"} mt={1}>
                                        <Typography variant={"body2"}>{plan.qrcodes === "unlimited" ? <b>{t("pricing:unlimited_qr_codes")}</b> : <><b>{plan.qrcodes}</b> QR Codes</>}</Typography>
                                        &nbsp;-&nbsp;
                                        <Typography variant={"body2"}><b>{plan.analytics}</b> {t("pricing:of_analytics")}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={4} alignContent={"center"}>
                                    <Typography variant={"caption"} component={"p"} align={"right"}>{t("14_free_trial")}</Typography>
                                    <Typography variant={"h5"} component={"p"} align={"right"}>
                                        {formatPrice(plan.price, currency)}&nbsp;
                                        <span style={{fontWeight: 300, fontSize: 12 }}>
                                            {currency === "$" ? "tax free" : t("pricing:without_vat")}
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </ButtonBase>
                </Grid>
            )
        }

        if (!firstVisitFunnel) return <></>
        if (!subscriptions) return <Grid item xs={12}><Skeleton variant={"rectangular"} width={"100%"} height={200} /></Grid>

        switch (true) {
            case firstVisitFunnel.includes("packaging"):
            case firstVisitFunnel.includes("standalone"):
            case firstVisitFunnel.includes("standard"):
            case firstVisitFunnel.includes("gold"):
            case firstVisitFunnel.includes("platinum"):
                break;
            case firstVisitFunnel.includes("-reco-std/gld"):
                displayedSubscriptions = [subscriptions.plans[0], subscriptions.plans[1]]
                break;
            case firstVisitFunnel.includes("-reco-gld/pla"):
                displayedSubscriptions = [subscriptions.plans[1], subscriptions.plans[2]]
                break;
            case firstVisitFunnel.includes("-reco-plat"):
                displayedSubscriptions = [subscriptions.plans[2]]
                break;
            default: break;
        }

        if (!displayedSubscriptions.length) return null

        return (
            <Grid item xs={12} container spacing={1} sx={{ pt: "0px!important" }}>
                <Grid item xs={12}>
                    <Typography align={"center"}>
                        {
                            (!!firstVisitFunnel && firstVisitFunnel.includes("-reco-plat")) ?
                                t("we_recommend_platinum")
                                : t("we_recommend")
                        }
                    </Typography>
                </Grid>
                {renderPlanPaper(displayedSubscriptions[0], true)}
                {(!!displayedSubscriptions && !!displayedSubscriptions[1]) && <Grid item xs={12}><Typography align={"center"}>{t("to_go_farther")}</Typography></Grid>}
                {renderPlanPaper(displayedSubscriptions[1])}
                <PlansFrequencySwitch {...{frequency, setFrequency, t}} dense />
                <Grid item xs={12}>
                    <Typography variant={"body2"} align={"center"}>
                        {t("no_credit_card_required")}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const renderDialogActions = () => {

        if (!firstVisitFunnel) {
            return (
                <Grid item xs={12} textAlign={"center"}>
                    <Button
                        variant={"contained"} size={"large"} fullWidth disableElevation color={"secondary"}
                        onClick={() => handleTourEnd("first-visit")}
                    >
                        {t("Close")}
                    </Button>
                </Grid>
            )
        } else {
            return (
                <>
                    {
                        !!displayedSubscriptions.length ?
                            <>
                                <Grid item xs={12} textAlign={"center"}>
                                    <Button
                                        variant={"outlined"}
                                        component={Link} to={"/plans"}
                                        onClick={() => handleTourEnd("first-visit")}
                                        sx={{ mr: 2 }}
                                    >
                                        {t("see_our_offers")}
                                    </Button>
                                    <Button
                                        disabled={!selectedPlan}
                                        variant={"contained"}
                                        // onClick={() => navigateToAlacarte(selectedPlan.link)}
                                        onClick={() => createSubscription()}
                                    >
                                        {t("start_free_trial")}
                                    </Button>
                                </Grid>
                            </>
                            :
                            <Grid item xs={12} textAlign={"center"}>
                                <Button
                                    variant={"contained"}
                                    onClick={() => setFirstVisitFunnel(null)}
                                >
                                    {t("Next")}
                                </Button>
                            </Grid>
                    }
                </>
            )
        }
    }

    const renderCloseButton = () => {

        const handleCloseDialog = () => {
            if (!!firstVisitFunnel) setFirstVisitFunnel("")
            else handleTourEnd("first-visit")
        }

        return (
            <Box sx={{
                position: "absolute",
                top: 8, right: 8
            }}>
                <IconButton onClick={() => handleCloseDialog()}>
                    <Close />
                </IconButton>
            </Box>
        )
    }

    return (
        <Dialog
            open={openDialog}
            maxWidth={"sm"}
            fullWidth
            PaperProps={{
                sx: { borderRadius: 5 }
            }}
            hideBackdrop={isFirstVisit && !firstVisitFunnel}
        >
            <DialogContent sx={{ p: 0 }}>
                {renderCloseButton()}
                <Grid container spacing={2} justifyContent={"center"} sx={{ px: 2, pt: 2, pb: !!firstVisitFunnel ? 0 : 2 }}>
                    {renderDialogTitle()}
                    {renderDialogIntro()}
                    {renderDialogRecommendations()}
                    {renderDialogActions()}
                </Grid>
                {
                    !!firstVisitFunnel &&
                    <Box sx={{ backgroundColor: "#efefef", py: 2, mt: 2 }}>
                        <Typography
                            align={"center"}
                            variant={"body2"}
                            sx={styles.skipText}
                            onClick={() => setFirstVisitFunnel("")}
                        >
                            {t("skip_upsale")}
                        </Typography>
                    </Box>
                }
            </DialogContent>
        </Dialog>
    )
}